"use client";

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { MdLocalShipping } from "react-icons/md";

interface ProjectData {
  project_name: string;
  project_id: string;
  address: string;
  department: string;
  start_date: string;
  end_date: string;
}

interface SimpleProps {
  projectData: ProjectData;
}

const generateRandomData = () => {
  const projects: Array<ProjectData> = [];
  const departments = ["HR", "Finance", "Development", "Marketing", "Sales"];
  const randomDate = () =>
    new Date(Date.now() + Math.floor(Math.random() * 1e10))
      .toISOString()
      .split("T")[0];

  for (let i = 0; i < 10; i++) {
    // projects.push({
    //   project_name: `Project ${i + 1}`,
    //   project_id: `P-${Math.floor(Math.random() * 10000)}`,
    //   address: `${Math.floor(Math.random() * 1000)} Main St`,
    //   department: departments[Math.floor(Math.random() * departments.length)],
    //   start_date: randomDate(),
    //   end_date: randomDate(),
    // });
     projects.push({
       project_name: `Nirsa Site, Dhanbad`,
       project_id: `P-BPC001`,
       address: `Nirsa, Dhanbad`,
       department: "Civil",
       start_date: "06/06/2024",
       end_date: "N/A",
     });
  }
  return projects;
};

const projects = generateRandomData();

const SiteProfile = () => {
  const projectData: ProjectData = projects[0];

  return (
    <Container maxW={"7xl"}>
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 18, md: 24 }}
      >
        <Flex>
          <Image
            rounded={"md"}
            alt={"product image"}
            src={
              "https://res.cloudinary.com/dfhnhykia/image/upload/v1723277209/bpc/er7kg7rakz1fza8yzbyw.jpg"
            }
            fit={"cover"}
            align={"center"}
            w={"100%"}
            h={{ base: "100%", sm: "400px", lg: "500px" }}
            p={4}
            border={"1px solid black"}
          />
        </Flex>
        <Stack spacing={{ base: 6, md: 10 }}>
          <Box as={"header"}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
            >
              {projectData.project_name}
            </Heading>
            <Text
              color={useColorModeValue("gray.900", "gray.400")}
              fontWeight={300}
              fontSize={"2xl"}
            >
              Project ID: {projectData.project_id}
            </Text>
          </Box>

          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={"column"}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.200", "gray.600")}
              />
            }
          >
            <VStack textAlign={"start"} spacing={{ base: 4, sm: 6 }}>
              <Text
                width={"100%"}
                color={useColorModeValue("gray.500", "gray.400")}
                fontSize={"2xl"}
                fontWeight={"300"}
              >
                Address: {projectData.address}
              </Text>
              <Text width={"100%"} fontSize={"lg"}>
                Department: {projectData.department}
              </Text>
              <Text width={"100%"} fontSize={"lg"}>
                Start Date: {projectData.start_date}
              </Text>
              <Text width={"100%"} fontSize={"lg"}>
                End Date: {projectData.end_date}
              </Text>
            </VStack>
          </Stack>

          {/* <Button
            rounded={"none"}
            w={"full"}
            mt={8}
            size={"lg"}
            py={"7"}
            bg={useColorModeValue("gray.900", "gray.50")}
            color={useColorModeValue("white", "gray.900")}
            textTransform={"uppercase"}
            _hover={{
              transform: "translateY(2px)",
              boxShadow: "lg",
            }}
          >
            Update Project
          </Button> */}

          {/* <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <MdLocalShipping />
            <Text>2-3 business days delivery</Text>
          </Stack> */}
        </Stack>
      </SimpleGrid>
    </Container>
  );
};

export default SiteProfile;
