import { IconButton, useDisclosure } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { FiEdit2 } from "react-icons/fi";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useRecoilState } from "recoil";
import * as yup from "yup";
import {
  useGet,
  useLateGet,
  usePost,
} from "../../../../../Services/API/APIHandlers";
import { accessType } from "../../../../../Services/Atoms/AuthAtoms";
import { materialData } from "../../../../../Services/Atoms/MaterialAtoms";
import { formValuesTable } from "../../../../../Services/Types/MaterialType";

interface SelectOption {
  value: string | number;
  label: string | number;
}

const blockOptions: SelectOption[] = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "AT", label: "AT" },
  { value: "ABT", label: "ABT" },
  { value: "BCT", label: "BCT" },
  { value: "CT1", label: "CT1" },
  { value: "CT2", label: "CT2" },
  { value: "DT1", label: "DT1" },
  { value: "DT2", label: "DT2" },
];

const columnOptions: SelectOption[] = Array.from({ length: 16 }, (v, k) => ({
  value: k + 1,
  label: k + 1,
}));

const cellOptions: SelectOption[] = [
  { value: "001", label: "001" },
  { value: "002", label: "002" },
  { value: "003", label: "003" },
  { value: "004", label: "004" },
  { value: "005", label: "005" },
];

const validationSchema = yup.object().shape({
  item_name: yup.object().required("Item Name is required").nullable(),
  lv: yup.number().required("LV is required").nullable(),
  lv_unit: yup.object().required("LV Unit is required").nullable(),
  quantity: yup.number().required("Quantity is required").nullable(),
  qt_unit: yup.object().required("Quantity Unit is required").nullable(),
  block: yup.object().required("Block is required").nullable(),
  column: yup.object().required("Column is required").nullable(),
  cell: yup.object().required("Cell is required").nullable(),
});

type formValues = {
  _id: string | null;
  item_name: { _id: string; label: string; value: string } | null;
  brand_name: string;
  lv: number | null;
  lv_unit: null | SelectOption;
  quantity: number | null;
  qt_unit: null | SelectOption;
  block: null | SelectOption;
  column: null | SelectOption;
  cell: null | SelectOption;
};

const lvUnitOptions: SelectOption[] = [
  { value: "inch", label: "inch" },
  { value: "cm", label: "cm" },
  { value: "m", label: "m" },
  { value: "ft", label: "ft" },
  { value: "litre", label: "litre" },
  { value: "kg", label: "kg" },
];

const qtUnitOptions: SelectOption[] = [
  { value: "kg", label: "kg" },
  { value: "litre", label: "litre" },
  { value: "gm", label: "gm" },
  { value: "pc", label: "pc" },
  { value: "bags", label: "bags" },
];

type PropType = {
  id: string;
};

type trData = {
  id: string;
  challanLink: string;
  createdAt: string;
};
const UpdateTransaction = (props: PropType) => {
  const [initialValues, setInitialValues] = useState<formValues>({
    _id: "",
    item_name: null,
    brand_name: "",
    lv: 0,
    lv_unit: null as SelectOption | null,
    quantity: 0,
    qt_unit: null as SelectOption | null,
    block: null as SelectOption | null,
    column: null as SelectOption | null,
    cell: null as SelectOption | null,
  });
  // {
  //   isOpen: boolean;
  //   onOpen: () => void;
  //   onClose: () => void;
  // > = ({ isOpen, onOpen, onClose }) => {
  const [mainTableItems, setMainTableItems] = useRecoilState(materialData);
  const [items, setItems] = useState<Array<formValuesTable> | null>(null);
  const [currentItems, setCurrentItems] =
    useState<Array<formValuesTable> | null>(null);
  const [accessTypeData, setAccessTypeData] = useRecoilState(accessType);

  const [barItems, setBarItems] = useState<
    Array<{ _id: string; label: string; value: string }>
  >([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = useState(false);

  const [updateCreate, setUpdateCreate] = useState<boolean>(false);
  const [iDToApprove, setIDToApprove] = useState<trData | null>(null);

  const [currentEditingIndex, setCurrentEditingIndex] = useState<number>(0);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (items && currentEditingIndex < items?.length) {
        setItems(
          items &&
            items.map((item: any, index: number) =>
              currentEditingIndex === index ? values : item
            )
        );
      } else {
        setItems(items ? [...items, values as any] : [values]);
      }
      setUpdateCreate(false);
      onClose();
    },
  });

  useEffect(() => {
    setCurrentEditingIndex(items ? items.length : 0);
  }, [items]);

  const {
    mutate: createMaterialItems,
    isLoading,
    error: materialDataError,
    data: materialsData,
    isSuccess,
  } = usePost("api/materials/remove");

  const {
    mutate: listBars,
    isLoading: isLoadingbars,
    error: listDataError,
    data: listBarData,
    isSuccess: isSuccessListBars,
  } = useLateGet();

  const {
    mutate: updateCurrentTransaction,
    isLoading: isLoadingUpdateCurrentTransaction,
    error: updateCurrentTransactionError,
    data: updateCurrentTransactionData,
    isSuccess: isSuccessUpdateCurrentTransaction,
  } = useGet("api/materials/transactions/" + props.id + "/complete");

  const {
    mutate: fetchCurrent,
    isLoading: isLoadingFetchCurrent,
    error: fetchCurrentError,
    data: fetchCurrentData,
    isSuccess: isSuccessFetchCurrent,
  } = useGet("api/materials/transactions/" + props.id);

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setMainTableItems(items ? materialsData.data.item : null);

      formik.setValues({ ...initialValues, quantity: 0, lv: 0 });
      setItems(null);
      toast.success(materialsData.data.message);
    }
  }, [isSuccess, materialsData]);

  useEffect(() => {
    if (isSuccessUpdateCurrentTransaction) {
      toast.success("Approved");
    }
  }, [isSuccessUpdateCurrentTransaction, updateCurrentTransactionData]);

  useEffect(() => {
    if (isSuccessListBars && listBarData) {
      setBarItems(
        listBarData.data.map((item: formValuesTable) => {
          return {
            _id: item._id,
            value: item.item_name?.value,
            label: item.item_name?.label,
          };
        })
      );
      setCurrentItems(listBarData.data);
    }
  }, [isSuccessListBars, listBarData]);

  useEffect(() => {
    if (isSuccessFetchCurrent) {
      console.log(fetchCurrentData.data.items);
      setItems(fetchCurrentData.data.items);
      setIDToApprove({
        id: fetchCurrentData.data._id,
        challanLink: fetchCurrentData.data.challanLink,
        createdAt: fetchCurrentData.data.createdAt,
      });
    }
  }, [isSuccessFetchCurrent, materialsData]);

  return (
    <>
      <IconButton
        className="rounded-circle"
        icon={<FiEdit2 fontSize="1rem" />}
        variant="ghost"
        aria-label="Update User Info"
        onClick={() => {
          fetchCurrent();
          setShow(true);
        }}
      />

      <Modal
        isCentered
        onClose={onClose}
        fullscreen={true}
        // show={isOpen}
        onHide={() => setShow(false)}
        show={show}
        className="w-100"
        size="xl"
      >
        <Modal.Dialog style={{ maxWidth: "inherit" }}>
          <Modal.Body className="w-100" style={{ padding: "20px" }}>
            <Modal.Header closeButton>
              <Modal.Title>Update Transaction</Modal.Title>
            </Modal.Header>
            <Row>
              <Col md={5}>
                <Form onSubmit={formik.handleSubmit}>
                  <Card className="rounded-5 mt-3 border-muted">
                    <Card.Body>
                      <Row>
                        <Col md={12}>
                          <Form.Group className="pt-3" controlId="item_name">
                            <Form.Label>Item Name</Form.Label>
                            <CreatableSelect
                              isClearable
                              options={barItems}
                              value={formik.values.item_name}
                              onChange={(option) => {
                                formik.setFieldValue("item_name", option);
                                if (option) {
                                  // If an item is selected from barItems
                                  const foundItem = currentItems?.find(
                                    (value) => value._id === option._id
                                  );
                                  if (foundItem) {
                                    formik.setValues({
                                      ...foundItem,
                                      quantity: 0,
                                    });
                                    // currentItems?.push(foundItem)
                                  } else {
                                    // If the selected item is not found in currentItems, add it
                                    formik.setValues({
                                      ...formik.values,
                                      item_name: option,
                                      _id: "",
                                      quantity: 0,
                                    });
                                    // if (currentItems)
                                    //   setCurrentItems([
                                    //     ...currentItems,
                                    //     option,
                                    //   ]);
                                    // else {
                                    // }
                                  }
                                }
                              }}
                              onInputChange={(inputValue, actionMeta) => {
                                if (
                                  actionMeta.action === "input-change" &&
                                  inputValue.match(/[a-zA-Z]/)
                                ) {
                                  listBars(
                                    "api/materials/list?q=" + inputValue
                                  );
                                }
                              }}
                              onBlur={() =>
                                formik.setFieldTouched("item_name", true)
                              }
                            />
                            {formik.touched.item_name &&
                              formik.errors.item_name && (
                                <div className="invalid-feedback d-block">
                                  {formik.errors.item_name}
                                </div>
                              )}
                          </Form.Group>
                        </Col>

                        <Col md={12}>
                          <Form.Group className="pt-3" controlId="lv">
                            <Form.Label>Brand Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Your Brand"
                              {...formik.getFieldProps("brand_name")}
                              isInvalid={
                                formik.touched.brand_name &&
                                !!formik.errors.brand_name
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.brand_name}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="lv">
                            <Form.Label>Length/Volume</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Length or Volume"
                              {...formik.getFieldProps("lv")}
                              isInvalid={
                                formik.touched.lv && !!formik.errors.lv
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.lv}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="lv_unit">
                            <Form.Label>LV Unit</Form.Label>
                            <Select
                              options={lvUnitOptions}
                              value={formik.values.lv_unit}
                              onChange={(option) =>
                                formik.setFieldValue("lv_unit", option)
                              }
                              onBlur={() =>
                                formik.setFieldTouched("lv_unit", true)
                              }
                            />
                            {formik.touched.lv_unit &&
                              formik.errors.lv_unit && (
                                <div className="invalid-feedback d-block">
                                  {formik.errors.lv_unit}
                                </div>
                              )}
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="quantity">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Quantity"
                              {...formik.getFieldProps("quantity")}
                              isInvalid={
                                formik.touched.quantity &&
                                !!formik.errors.quantity
                              }
                              onChange={(e) => {
                                const inputValue = parseFloat(e.target.value);
                                if (inputValue < 0) {
                                  formik.setFieldValue("quantity", 0);
                                } else {
                                  formik.setFieldValue("quantity", inputValue);
                                }
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.quantity}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="qt_unit">
                            <Form.Label>Quantity Unit</Form.Label>
                            <Select
                              options={qtUnitOptions}
                              value={formik.values.qt_unit}
                              onChange={(option) =>
                                formik.setFieldValue("qt_unit", option)
                              }
                              onBlur={() =>
                                formik.setFieldTouched("qt_unit", true)
                              }
                            />
                            {formik.touched.qt_unit &&
                              formik.errors.qt_unit && (
                                <div className="invalid-feedback d-block">
                                  {formik.errors.qt_unit}
                                </div>
                              )}
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="block">
                            <Form.Label>Block</Form.Label>
                            <Select
                              options={blockOptions}
                              value={formik.values.block}
                              onChange={(option) =>
                                formik.setFieldValue("block", option)
                              }
                              onBlur={() =>
                                formik.setFieldTouched("block", true)
                              }
                            />
                            {formik.touched.block && formik.errors.block && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.block}
                              </div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="column">
                            <Form.Label>Column</Form.Label>
                            <Select
                              options={columnOptions}
                              value={formik.values.column}
                              onChange={(option) =>
                                formik.setFieldValue("column", option)
                              }
                              onBlur={() =>
                                formik.setFieldTouched("column", true)
                              }
                            />
                            {formik.touched.column && formik.errors.column && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.column}
                              </div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="cell">
                            <Form.Label>Cell</Form.Label>
                            <Select
                              options={cellOptions}
                              value={formik.values.cell}
                              onChange={(option) =>
                                formik.setFieldValue("cell", option)
                              }
                              onBlur={() =>
                                formik.setFieldTouched("cell", true)
                              }
                            />
                            {formik.touched.cell && formik.errors.cell && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.cell}
                              </div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col md={12}>
                          <Button
                            className="btn btn-primary mt-4 d-block text-uppercase fw-bold w-100"
                            type="submit"
                          >
                            {updateCreate ? "Update" : "Create"} Item
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
              <Col md={7}>
                <h4 className="text-center mt-4">Items List</h4>
                <Table bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Item Name</th>
                      <th>LV</th>
                      <th>LV Unit</th>
                      <th>Quantity</th>
                      <th>Quantity Unit</th>
                      <th>Block</th>
                      <th>Column</th>
                      <th>Cell</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items &&
                      items.map((item: formValuesTable, index: number) => (
                        <tr
                          key={index}
                          onClick={() => {
                            setInitialValues(item);
                            setCurrentEditingIndex(index);
                            formik.setValues(item);
                            setUpdateCreate(true);
                          }}
                        >
                          <td>{index + 1}</td>
                          <td>{item.item_name?.value}</td>
                          <td>{item.lv}</td>
                          <td>{item.lv_unit?.label}</td>
                          <td>{item.quantity ? item?.quantity : ""}</td>
                          <td>{item.qt_unit?.label}</td>
                          <td>{item.block?.label}</td>
                          <td>{item.column?.label}</td>
                          <td>{item.cell?.label}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <Button
                  className="btn btn-danger mt-4 d-block text-uppercase fw-bold w-100"
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (items) createMaterialItems({ items });
                  }}
                  disabled={items ? items.length === 0 : false}
                >
                  Submit
                </Button>
                {accessTypeData === "SUPER_ADMIN" ||
                accessTypeData === "SUPER ADMIN" ? (
                  <Button
                    className="btn mt-4 d-block text-uppercase fw-bold w-100"
                    onClick={(e: any) => {
                      e.preventDefault();
                      if (items) updateCurrentTransaction();
                    }}
                    disabled={items ? items.length === 0 : false}
                  >
                    Approve
                  </Button>
                ) : (
                  <></>
                )}
                {/* <Col md={12}>
                  <FileUpload />
                </Col> */}
              </Col>
            </Row>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default UpdateTransaction;
