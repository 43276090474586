import { Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { useNavigate, useParams } from "react-router-dom";
import { BarLoader } from "react-spinners";
import { useRecoilState } from "recoil";
import { accessType } from "../../Services/Atoms/AuthAtoms";
import { Departments } from "../../Services/Constants";

type DepartmentDataType = {
  name: string;
  backgroundImage: string;
  icon: IconType;
};

function DepartmentLoader() {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<DepartmentDataType | null>(null);

  const [accessTypeData, setAccessTypeData] = useRecoilState(accessType);

  const navigate = useNavigate();

  useEffect(() => {
    setData(Departments(id));

    setTimeout(() => {
      const path =
        accessTypeData === "SUPER_ADMIN" ? "/dashboard" : "/inventory";
      navigate(path);
    }, 3000);
  }, [id]);

  return (
    <div
      style={{
        height: "100vh",
        background: `url(${data?.backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
      className="d-flex flex-row justify-content-center align-items-center"
    >
      <div
        style={{
          height: "100vh",
          backgroundColor: "#00000099",
        }}
        className="d-flex w-100 flex-column justify-content-center align-items-center"
      >
        <div className="d-flex flex-column justify-content-center align-items-end">
          <h1
            style={{ filter: "drop-shadow(0 0 10px #ccc)" }}
            className="display-1 fst-italic fw-bolder text-light d-flex flex-row justify-content-center align-items-center"
          >
            <Icon as={data?.icon} color="muted" /> &nbsp; {data?.name}
          </h1>
          <BarLoader
            color={"#fff"}
            loading={true}
            //   cssOverride={override}
            //   size={150}
            width={300}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    </div>
  );
}

export default DepartmentLoader;
