import { Box } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { BASE_URL } from "../../../Services/Config";

interface Site {
  _id: string;
  project_name: string;
  project_id: string;
  address: string;
  department: string;
  start_date: string;
  end_date?: string;
}

interface SiteSelectProps {
  onSiteChange: (site: Site | null) => void;
}

const SiteSelect: React.FC<SiteSelectProps> = ({ onSiteChange }) => {
  const [sites, setSites] = useState<Site[]>([]);
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);

  useEffect(() => {
    // Fetch sites from the API
    axios
      .get<Site[]>(BASE_URL + "api/fetch-all-sites")
      .then((response) => {
        setSites(response.data);
      })
      .catch((error) => {
        console.error("Error fetching sites:", error);
      });
  }, []);

  const handleSiteChange = (selectedOption: any) => {
    const site = sites.find((site) => site._id === selectedOption.value);
    setSelectedSite(site || null);
    onSiteChange(site || null); // Call the callback function with the selected site
  };

  return (
    <Box minWidth={100} zIndex={1000}>
      <Select
        options={sites.map((site) => ({
          value: site._id,
          label: site.project_name,
        }))}
        onChange={handleSiteChange}
      />
      {/* {selectedSite && (
        <Box mt={4}>
          <Text>Selected Site Details:</Text>
          <Text>Project Name: {selectedSite.project_name}</Text>
          <Text>Project ID: {selectedSite.project_id}</Text>
          <Text>Address: {selectedSite.address}</Text>
          <Text>Department: {selectedSite.department}</Text>
          <Text>
            Start Date: {new Date(selectedSite.start_date).toLocaleDateString()}
          </Text>
          {selectedSite.end_date && (
            <Text>
              End Date: {new Date(selectedSite.end_date).toLocaleDateString()}
            </Text>
          )}
        </Box>
      )} */}
    </Box>
  );
};

export default SiteSelect;
