import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { FiEdit2 } from "react-icons/fi";
import { useRecoilState } from "recoil";
import * as Yup from "yup";
import { usePut } from "../../../../../Services/API/APIHandlers"; // Assuming usePut for PUT request
import { labourPaymentData } from "../../../../../Services/Atoms/MaterialAtoms";
import { LabourPaymentValues } from "../../../../../Services/Interfaces/UserInterface";

type IdentifyProps = {
  paymentData: LabourPaymentValues;
};

const UpdatePayment = (props: IdentifyProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [members, setMembers] = useRecoilState(labourPaymentData);

  const {
    mutate: updatePayment,
    isLoading,
    error: updatePaymentError,
    data: updatePaymentData,
    isSuccess: updatePaymentSuccess,
  } = usePut(`api/labourPayments/${props.paymentData._id}`); // Adjust API endpoint for PUT request

  useEffect(() => {
    if (updatePaymentError instanceof AxiosError) {
      toast.error(updatePaymentError?.response?.data.message);
    }
  }, [updatePaymentError]);

  useEffect(() => {
    if (updatePaymentSuccess) {
      // Optionally update local state or trigger a reload of payments
      if (members) setMembers(updatePaymentData.data.data);
      toast.success("Payment updated successfully");
      onClose();
    }
  }, [updatePaymentSuccess, onClose]);

  const validationSchema = Yup.object().shape({
    referenceId: Yup.string().required("Reference ID is required"),
    name: Yup.string().required("Name is required"),
    amount: Yup.number().required("Amount is required"),
    tds: Yup.number().required("TDS is required"),
    paymentDate: Yup.date().required("Payment Date is required"),
    utr: Yup.string(),
    tcs: Yup.number(),
    remark: Yup.string(),
    panNumber: Yup.string().required("PAN Number is required"),
    accountHolderName: Yup.string().required("Account Holder Name is required"),
    accountNumber: Yup.string().required("Account Number is required"),
    ifscCode: Yup.string().required("IFSC Code is required"),
    bankName: Yup.string().required("Bank Name is required"),
    branch: Yup.string().required("Branch is required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: props.paymentData._id,
      referenceId: props.paymentData.referenceId,
      name: props.paymentData.name,
      amount: props.paymentData.amount,
      tds: props.paymentData.tds,
      paymentDate: new Date().toISOString(), // Assuming paymentDate is a Date object
      utr: props.paymentData.utr || "",
      tcs: props.paymentData.tcs || 0,
      remark: props.paymentData.remark || "",
      panNumber: props.paymentData.panNumber,
      accountHolderName: props.paymentData.accountHolderName,
      accountNumber: props.paymentData.accountNumber,
      ifscCode: props.paymentData.ifscCode,
      bankName: props.paymentData.bankName,
      branch: props.paymentData.branch,
    },
    validationSchema: validationSchema,
    onSubmit: (values: LabourPaymentValues) => {
      updatePayment(values);
    },
  });

  return (
    <>
      <IconButton
        icon={<FiEdit2 fontSize="1rem" />}
        className="rounded-circle"
        variant="ghost"
        aria-label="Edit Labour Payment"
        onClick={onOpen}
      />

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size={"xl"}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody padding={"20px"}>
            <ModalCloseButton />
            <h4 className="text-center mb-3">Update Payment</h4>
            <Form onSubmit={formik.handleSubmit}>
              <Card className="rounded-5 border-muted">
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="referenceId">
                        <Form.Label>Reference ID</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Reference ID"
                          {...formik.getFieldProps("referenceId")}
                          isInvalid={
                            formik.touched.referenceId &&
                            !!formik.errors.referenceId
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.referenceId}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          {...formik.getFieldProps("name")}
                          isInvalid={
                            formik.touched.name && !!formik.errors.name
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="amount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Amount"
                          {...formik.getFieldProps("amount")}
                          isInvalid={
                            formik.touched.amount && !!formik.errors.amount
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.amount}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="tds">
                        <Form.Label>TDS</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="TDS"
                          {...formik.getFieldProps("tds")}
                          isInvalid={formik.touched.tds && !!formik.errors.tds}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.tds}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="paymentDate">
                        <Form.Label>Payment Date</Form.Label>
                        <Form.Control
                          type="date"
                          {...formik.getFieldProps("paymentDate")}
                          value={
                            formik.values.paymentDate
                              ? new Date(formik.values.paymentDate)
                                  .toISOString()
                                  .split("T")[0]
                              : new Date().toISOString().split("T")[0]
                          }
                          isInvalid={
                            formik.touched.paymentDate &&
                            !!formik.errors.paymentDate
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.paymentDate}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="utr">
                        <Form.Label>UTR</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="UTR"
                          {...formik.getFieldProps("utr")}
                          isInvalid={formik.touched.utr && !!formik.errors.utr}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.utr}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="tcs">
                        <Form.Label>TCS</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="TCS"
                          {...formik.getFieldProps("tcs")}
                          isInvalid={formik.touched.tcs && !!formik.errors.tcs}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.tcs}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="panNumber">
                        <Form.Label>PAN Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="PAN Number"
                          {...formik.getFieldProps("panNumber")}
                          isInvalid={
                            formik.touched.panNumber &&
                            !!formik.errors.panNumber
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.panNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="pt-3"
                        controlId="accountHolderName"
                      >
                        <Form.Label>Account Holder Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Account Holder Name"
                          {...formik.getFieldProps("accountHolderName")}
                          isInvalid={
                            formik.touched.accountHolderName &&
                            !!formik.errors.accountHolderName
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.accountHolderName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="accountNumber">
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Account Number"
                          {...formik.getFieldProps("accountNumber")}
                          isInvalid={
                            formik.touched.accountNumber &&
                            !!formik.errors.accountNumber
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.accountNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="ifscCode">
                        <Form.Label>IFSC Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="IFSC Code"
                          {...formik.getFieldProps("ifscCode")}
                          isInvalid={
                            formik.touched.ifscCode && !!formik.errors.ifscCode
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.ifscCode}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="bankName">
                        <Form.Label>Bank Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Bank Name"
                          {...formik.getFieldProps("bankName")}
                          isInvalid={
                            formik.touched.bankName && !!formik.errors.bankName
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.bankName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="branch">
                        <Form.Label>Branch</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Branch"
                          {...formik.getFieldProps("branch")}
                          isInvalid={
                            formik.touched.branch && !!formik.errors.branch
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.branch}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="pt-3" controlId="remark">
                        <Form.Label>Remark</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Remark"
                          {...formik.getFieldProps("remark")}
                          isInvalid={
                            formik.touched.remark && !!formik.errors.remark
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.remark}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Button
                className="btn btn-primary mt-4 d-block text-uppercase w-100 fw-bold"
                type="submit"
              >
                Update Payment
              </Button>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdatePayment;
