import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accessType } from "../../../Services/Atoms/AuthAtoms";
import Navbar from "../../components/Navbar";
import { Table } from "../Users/UsersTable/index";

function DashboardHome() {
  const [accessTypeData, setAccessTypeData] = useRecoilState(accessType);

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (accessTypeData !== "SUPER_ADMIN" && accessTypeData !== "SUPER ADMIN") {
  //     navigate("/inventory");
  //   }
  // }, []);
  return (
    <div>
      {/* <Navbar /> */}
      <Table />
    </div>
  );
}

export default DashboardHome;
