import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import toast from "react-hot-toast";
import { usePut } from "../../../Services/API/APIHandlers";
import { BASE_URL } from "../../../Services/Config";

interface ProfilePictureCropProps {
  isOpen: boolean;
  onClose: () => void;
  onCrop: (blob: Blob) => void;
  imageSrc: string;
  userid: string | null;
  data: any;
}

const ProfilePictureCrop: React.FC<ProfilePictureCropProps> = ({
  isOpen,
  onClose,
  onCrop,
  imageSrc,
  userid,
  data,
}) => {
  const editorRef = useRef<AvatarEditor | null>(null);
  const [scale, setScale] = useState(1);
  const [borderRadius, setBorderRadius] = useState(0);

  const [profilePhoto, setProfilePhoto] = useState<File | null>(null);

  const handleScaleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setScale(value);
  };

  const handleBorderRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    setBorderRadius(value);
  };

  const {
    mutate: updateImage,
    isSuccess: isUpdateImageSuccess,
    isLoading: isUpdateImageLoading,
    data: isUpdateImageData,
    error: isUpdateImageError,
  } = usePut("api/users/" + userid);

  const handleSave = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob((blob) => {
        if (blob) {
          onCrop(blob); // Pass the cropped blob back to Settings component
          setProfilePhoto(
            new File([blob], "cropped_image.jpg", { type: "image/jpeg" })
          );
        }
      });
      // handleUpload();
    }
  };

  console.log(profilePhoto);

  useEffect(() => {
    if (profilePhoto) {
      handleUpload();
    }
  }, [profilePhoto]);

  const handleUpload = async () => {
    if (!profilePhoto) {
      toast.error("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    formData.append("image", profilePhoto); // Make sure the field name matches your backend

    try {
      const response = await axios.post(
        BASE_URL + "api/upload-image", // Update the URL to match your backend
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      updateImage({ profilePicUrl: response.data.url, ...data });

      toast.success("Upload Successful");
      onClose();
    } catch (error) {
      toast.error("Please select a file to upload");
      onClose();

      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Crop Profile Picture</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <AvatarEditor
              ref={(ref) => (editorRef.current = ref)}
              image={imageSrc}
              width={300}
              height={300}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={scale}
              borderRadius={borderRadius}
              onLoadFailure={() => console.log("Image failed to load.")}
              onLoadSuccess={() => console.log("Image loaded successfully.")}
              onImageReady={() => console.log("Image ready for cropping.")}
            />
            <Input
              type="range"
              min="1"
              max="2"
              step="0.01"
              value={scale}
              onChange={handleScaleChange}
              style={{ width: "100%" }}
              mt={2}
            />
            <Input
              type="number"
              min="0"
              max="100"
              value={borderRadius}
              onChange={handleBorderRadiusChange}
              style={{ width: "100%" }}
              mt={2}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProfilePictureCrop;
