import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Sidebar from "../../Dashboard/components/Sidebar/index";
import { accessType } from "../Atoms/AuthAtoms";
import { sidebarOpen } from "../Atoms/MaterialAtoms";
import Navbar from "./../../Dashboard/components/Navbar/index";

type AuthRouteProps = {
  children: React.ReactNode | string;
};

const AuthRoute = (props: AuthRouteProps) => {
  const navigate = useNavigate();
  const [tokenValid, setIsTokenValid] = useState<boolean>(true);
  const isAuth = false;
  // const isAuth = useRecoilValue(isAuthenticated);
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(sidebarOpen);

  const [accessTypeData, setAccessTypeData] = useRecoilState(accessType);
  useEffect(() => {
    setIsTokenValid(false);
    setAccessTypeData(localStorage.getItem("access_type") || "");

    if (isAuth) {
      setIsTokenValid(true);
      navigate("/");
    }
  }, [isAuth]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <React.Fragment>
      <div className="container-fluid d-flex flex-row">
        {/* {tokenValid && ( */}
        <Sidebar isOpen={isSidebarOpen} onToggle={toggleSidebar} />
        {/* )} */}
        {isSidebarOpen && <div style={{ width: 284 }}></div>}
        <div className=" flex-lg-grow-1">
          <Navbar />
          {!tokenValid ? props.children : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AuthRoute;
