import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { FiEdit2 } from "react-icons/fi";
import { useRecoilState } from "recoil";
import * as Yup from "yup";
import { usePut } from "../../../../../Services/API/APIHandlers";
import { siteData } from "../../../../../Services/Atoms/MaterialAtoms";
import { ProjectData } from "../../../../../Services/Interfaces/UserInterface";

type propType = {
  projectData: ProjectData;
};

function UpdateSite(props: propType) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [members, setMembers] = useRecoilState(siteData);

  const {
    mutate: updateUser,
    isLoading,
    error: userDataError,
    data: userData,
    isSuccess,
  } = usePut("api/sites/update/" + props.projectData._id);

  useEffect(() => {
    if (userDataError instanceof AxiosError) {
      // Call a toast
      toast.error(userDataError?.response?.data.message);
    }
  }, [userDataError]);

  useEffect(() => {
    if (isSuccess) {
      if (members)
        setMembers(
          members.map((item: ProjectData) => {
            if (item._id !== props.projectData._id) {
              return item;
            } else {
              return userData.data.site;
            }
          })
        );
    }
  }, [isSuccess, userData]);

  const formik = useFormik({
    initialValues: {
      project_name: props.projectData.project_name || "",
      project_id: props.projectData.project_id || "",
      address: props.projectData.address || "",
      department: props.projectData.department || "",
      start_date: props.projectData.start_date || "",
      end_date: props.projectData.end_date || "",
    },
    validationSchema: Yup.object({
      project_name: Yup.string().required("Required"),
      project_id: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      department: Yup.string().required("Required"),
      start_date: Yup.date().required("Required"),
      end_date: Yup.date().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      updateUser(values);
      onClose();
    },
  });

  return (
    <>
      <IconButton
        className="rounded-circle"
        icon={<FiEdit2 fontSize="1rem" />}
        variant="ghost"
        aria-label="Update Project Info"
        onClick={onOpen}
      />

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size={"xl"}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody padding={"20px"}>
            <ModalCloseButton />
            <h4 className="text-center mb-3">Update Project Info</h4>
            <Form onSubmit={formik.handleSubmit}>
              <Card className="rounded-5 border-muted">
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="project_name">
                        <Form.Label>Name of Project</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name of Project"
                          {...formik.getFieldProps("project_name")}
                          isInvalid={
                            formik.touched.project_name &&
                            !!formik.errors.project_name
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.project_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="project_id">
                        <Form.Label>Project ID</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Project ID"
                          {...formik.getFieldProps("project_id")}
                          isInvalid={
                            formik.touched.project_id &&
                            !!formik.errors.project_id
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.project_id}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Address"
                          {...formik.getFieldProps("address")}
                          isInvalid={
                            formik.touched.address && !!formik.errors.address
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.address}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="department">
                        <Form.Label>Name Of Department</Form.Label>
                        <Form.Control
                          as="select"
                          {...formik.getFieldProps("department")}
                          isInvalid={
                            formik.touched.department &&
                            !!formik.errors.department
                          }
                        >
                          <option value="">Select Department</option>
                          <option value="MATERIAL_MANAGEMENT">
                            MATERIAL MANAGEMENT
                          </option>
                          <option value="ACCOUNTS">ACCOUNTS</option>
                          <option value="HR">HR</option>
                          <option value="FINANCE">FINANCE</option>
                          <option value="MARKETING">MARKETING</option>
                          <option value="PURCHASE">PURCHASE</option>
                          <option value="TENDER">TENDER</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.department}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="start_date">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          {...formik.getFieldProps("start_date")}
                          isInvalid={
                            formik.touched.start_date &&
                            !!formik.errors.start_date
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.start_date}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="end_date">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          {...formik.getFieldProps("end_date")}
                          isInvalid={
                            formik.touched.end_date && !!formik.errors.end_date
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.end_date}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Button
                className="btn btn-primary mt-4 d-block text-uppercase w-100 fw-bold"
                type="submit"
              >
                Update Project
              </Button>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UpdateSite;
