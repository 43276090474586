import {
  Box,
  HStack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { useGet } from "../../../../Services/API/APIHandlers";
import { transactionData } from "../../../../Services/Atoms/MaterialAtoms";
import UpdateTransaction from "../Forms/UserModal/OutwardTransactionData";
import ImagePreviewModal from "./../../../components/ImagePreview/index";
import Header from "./Header";

interface CsvData {
  Items: string;
  "Challan Link": string;
  Type: string;
  Status: string;
  "Created At": string;
}

export const InventoryTransactionTable = (props: TableProps) => {
  const [items, setItems] = useRecoilState(transactionData);

  const {
    mutate: getAllTransactions,
    isLoading,
    error: transactionDataError,
    data: transactionsData,
    isSuccess,
  } = useGet("api/materials/transactions");

  useEffect(() => {
    if (transactionDataError instanceof AxiosError) {
      toast.error(transactionDataError?.response?.data.message);
    }
  }, [transactionDataError]);

  useEffect(() => {
    if (isSuccess) {
      setItems(transactionsData.data);
    }
  }, [isSuccess, transactionsData]);

  useEffect(() => {
    getAllTransactions();
    window.scrollTo(0, 0);
  }, []);

  const csvFields = ["Items", "Challan Link", "Type", "Status", "Created At"];

  // Format the data for CSV export
  const csvData = items?.map((item) => ({
    Items: item.items,
    "Challan Link": item.challanLink,
    Type: item.type,
    Status: item.status,
    "Created At": item.createdAt,
  }));

  return (
    <>
      <Header csvData={csvData} csvFields={csvFields} />
      <Box overflowY="auto" maxHeight="500px">
        <Table {...props}>
          <Thead position="sticky" top="0" zIndex="1" bg="gray.100">
            <Tr>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Transaction ID</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Challan Number</Text>
                  </HStack>
                </HStack>
              </Th>
              {/* <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Items</Text>
                  </HStack>
                </HStack>
              </Th> */}
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Site Transfer</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Challan Link</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Type</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Status</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Created At</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody fontSize={"15px"}>
            {items &&
              items.map((item, index) => (
                <Tr key={index}>
                  <Td>
                    <Text className="mb-0" color="muted">
                      {item._id.substring(0, 8)}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="muted">
                      00001
                    </Text>
                  </Td>
                  {/* <Td> */}
                    {/* <Text className="mb-0" color="muted">
                      {item.items}
                    </Text> */}
                  {/* </Td> */}
                  <Td>
                    <Text className="mb-0" color="muted">
                      WAREHOUSE TO T.Tangar
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="muted">
                      {item.challanLink && (
                        <ImagePreviewModal imageUrl={item.challanLink || ""} />
                      )}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="#777">
                      {item.type}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="#777">
                      {item.status}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="#777">
                      {item.createdAt}
                    </Text>
                  </Td>
                  <Td>
                    <UpdateTransaction id={item._id} />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
