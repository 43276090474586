// src/Services/API/APIHandlers.ts
import axios from "axios";
import { RecoilState, atom } from "recoil";
import {
  LabourPaymentValues,
  PartyPayment,
  ProjectData,
} from "../Interfaces/UserInterface";
import { formValuesTable } from "../Types/MaterialType";
import { TransactionType } from "../Types/TransactionType";

export const materialData: RecoilState<Array<formValuesTable> | null> =
  atom<Array<formValuesTable> | null>({
    key: "MATERIAL_DATA",
    default: null,
  });

export const transactionData: RecoilState<Array<TransactionType> | null> =
  atom<Array<TransactionType> | null>({
    key: "TransactionType",
    default: null,
  });

export const siteData: RecoilState<Array<ProjectData> | null> =
  atom<Array<ProjectData> | null>({
    key: "SITE_DATA",
    default: null,
  });

export const labourPaymentData: RecoilState<Array<LabourPaymentValues> | null> =
  atom<Array<LabourPaymentValues> | null>({
    key: "LabourPaymentValues",
    default: null,
  });

export const partyPaymentData = atom<PartyPayment[]>({
  key: "partyPaymentData",
  default: [],
});


export const sidebarOpen = atom<boolean>({
  key: "sidebarOpen",
  default: true,
});

const api = axios.create({
  baseURL: "http://localhost:5000/api",
});

export const getPartyPayments = () =>
  api.get<PartyPayment[]>("/party-payments");
export const createPartyPayment = (payment: PartyPayment) =>
  api.post("/party-payments", payment);
export const updatePartyPayment = (id: string, payment: PartyPayment) =>
  api.put(`/party-payments/${id}`, payment);
export const deletePartyPayment = (id: string) =>
  api.delete(`/party-payments/${id}`);
