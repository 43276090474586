// src/components/NotificationProvider.tsx
import React, { createContext, useContext } from "react";
import useNotificationService from "../hooks/useNotificationService";

const NotificationContext = createContext(null);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // useNotificationService(); // Initialize the notification service

  return (
    <NotificationContext.Provider value={null}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);
