import {
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  Stack,
} from "@chakra-ui/react";
import { BsArrowRepeat } from "react-icons/bs";
import { GoChevronDown, GoChevronLeft, GoChevronRight } from "react-icons/go";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoIosList } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import CreateUser from "../Forms/UserModal/CreatePayment";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

function Header() {
  return (
    <>
      <Box px={{ base: "4", md: "6" }} pt="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="dropdown show">
              <Link
                className="fs-7 py-1 d-flex flex-row align-items-center me-1 dropdown-toggle"
                to="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <h4 className="my-0">Labour Payment</h4>
              </Link>

              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a className="dropdown-item" href="#">
                  Action
                </a>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
            </div>
            <div className="dropdown">
              <Link
                className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Create Payment
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <CreateUser />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
          </HStack>
          <InputGroup maxW="xs" className="justify-content-end">
            <IconButton
              icon={<IoIosList />}
              variant={"none"}
              className="text-dark fs-5"
              aria-label={"More"}
            />
            <IconButton
              icon={<IoSettingsOutline />}
              variant={"none"}
              className="text-dark fs-5"
              aria-label="Settings"
            />
            <div className="mx-3 border-end"></div>
            <IconButton
              icon={<BsArrowRepeat />}
              variant={"none"}
              aria-label="Refresh"
              className="text-dark fs-5"
            />
            <IconButton
              variant={"none"}
              className="text-dark fs-5"
              aria-label={"More Button"}
              icon={<HiOutlineDotsVertical />}
            />
            <IconButton
              aria-label="Additional Items"
              variant={"none"}
              className="text-dark fs-5"
              icon={<GoChevronDown />}
            />
          </InputGroup>
          {/* <InputGroup maxW="xs">
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="muted" boxSize="5" />
          </InputLeftElement>
          <Input placeholder="Search" />
        </InputGroup> */}
        </Stack>
      </Box>
      <hr />

      <Box px={{ base: "4", md: "6" }} pt="3" pb="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="dropdown">
              <Link
                className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Bulk Actions
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <CreateUser />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>

            <Button colorScheme="gray" size={"sm"} fontWeight={"normal"}>
              Apply
            </Button>
            <div className="d-flex flex-row align-items-center">
              <span className="px-2 pe-3">Sort By: </span>
              <div className="dropdown show">
                <a
                  className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Bulk actions
                </a>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </div>
            </div>
            <div className="dropdown show">
              <a
                className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Export to CSV
              </a>

              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a className="dropdown-item" href="#">
                  Action
                </a>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
            </div>
          </HStack>
          <div className="d-flex align-items-center">
            <InputGroup maxW="xs" padding={0}>
              <Input
                placeholder="Search"
                size="sm"
                borderRadius={6}
                width={"auto"}
              />
            </InputGroup>
            <span className="ps-4 d-flex">
              <button className="btn text-dark p-1">
                <GoChevronLeft fontSize={18} />
              </button>
              <button className="btn text-dark p-1">
                <GoChevronRight fontSize={18} />
              </button>
            </span>
          </div>
        </Stack>
      </Box>
    </>
  );
}

export default Header;
