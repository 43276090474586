import {
  Collapse,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
  FiBookmark,
  FiChevronDown,
  FiChevronUp,
  FiHelpCircle,
  FiSearch,
  FiSettings,
  FiUsers,
} from "react-icons/fi";
import { LuArrowLeftToLine, LuArrowRightToLine } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accessType } from "../../../Services/Atoms/AuthAtoms";
import { sidebarOpen } from "../../../Services/Atoms/MaterialAtoms";
import { NavButton } from "./NavButton";

type SidebarProps = {
  isOpen: boolean;
  onToggle: () => void;
};

function Sidebar({ isOpen, onToggle }: SidebarProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(sidebarOpen);
  const [sidebarWidth, setSidebarWidth] = useState<number>(256);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [activeNav, setActiveNav] = useState<string>("Users");

  const [accessTypeData, setAccessTypeData] = useRecoilState(accessType);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const [isMaterialOpen, setIsMaterialOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(isOpen);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  const handleNavClick = (label: string, path: string) => {
    setActiveNav(label);
    navigate(path);
  };

  return (
    <>
      <Flex
        flex="1"
        backgroundColor="white"
        overflowY="auto"
        position={"fixed"}
        ref={sidebarRef}
        height="100vh"
        zIndex={1}
        boxShadow={useColorModeValue("sm", "sm-dark")}
        borderRight="1px solid #ddd"
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "3", sm: "4" }}
        px={{ base: "3", sm: "4" }}
        minW={256}
        transition="transform 0.3s ease-in-out"
        transform={isOpen ? "translateX(0)" : "translateX(-100%)"}
      >
        <Stack width="100%" justify="space-between">
          <Stack shouldWrapChildren width="100%" spacing="1">
            <HStack
              paddingY={4}
              paddingBottom={6}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <h4>BPC Infraprojects</h4>
              <IconButton
                variant="ghost"
                className="rounded-circle"
                aria-label="Toggle Sidebar"
                icon={<LuArrowLeftToLine fontSize="1rem" />}
                onClick={onToggle}
              />
            </HStack>
            <Stack spacing="1">
              <InputGroup>
                <InputLeftElement
                  marginBottom={64}
                  maxW="xs"
                  pointerEvents="none"
                >
                  <Icon as={FiSearch} color="muted" boxSize="5" />
                </InputLeftElement>
                <Input placeholder="Search" />
              </InputGroup>
              <NavButton
                onClick={(e) => {
                  e.preventDefault();
                  handleNavClick("Site", "/sites");
                }}
                label="Site"
                icon={FiBookmark}
                isActive={activeNav === "Site"}
              />
              {/* <NavButton
                onClick={() => setIsMaterialOpen(!isMaterialOpen)}
                label="Account"
                icon={isMaterialOpen ? FiChevronUp : FiChevronDown}
                isActive={
                  activeNav === "Material Management" ||
                  activeNav === "Material Transactions"
                }
              /> */}
              {/* <Collapse in={isMaterialOpen}>
                <Stack pl={6} mt={1} spacing={1}>
                  <NavButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavClick("Labour Payment", "/labour-payment");
                    }}
                    label="Labour Payment"
                    icon={FiBookmark}
                    isActive={activeNav === "Labour Payment"}
                  />
                  <NavButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavClick("Party Payment", "/party-payment");
                    }}
                    label="Party Payment"
                    icon={FiBookmark}
                    isActive={activeNav === "Party Payment"}
                  />
                </Stack>
              </Collapse> */}
              <NavButton
                onClick={() => setIsAccountOpen(!isAccountOpen)}
                label="Material Management"
                icon={isAccountOpen ? FiChevronUp : FiChevronDown}
                isActive={
                  activeNav === "Labour Payment" ||
                  activeNav === "Party Payment"
                }
              />
              <Collapse in={isAccountOpen}>
                <Stack pl={6} mt={1} spacing={1}>
                  <NavButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavClick("Material Management", "/inventory");
                    }}
                    label="Inventory"
                    icon={FiBookmark}
                    isActive={activeNav === "Material Management"}
                  />
                  <NavButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavClick(
                        "Material Transactions",
                        "/inventory/transactions"
                      );
                    }}
                    label="Material Transaction"
                    icon={FiBookmark}
                    isActive={activeNav === "Material Transaction"}
                  />
                </Stack>
              </Collapse>
              {accessTypeData === "SUPER_ADMIN" ||
              accessTypeData === "SUPER ADMIN" ? (
                <NavButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavClick("Users", "/dashboard");
                  }}
                  label="Users"
                  isActive={activeNav === "Users"}
                  icon={FiUsers}
                />
              ) : (
                <></>
              )}{" "}
            </Stack>
          </Stack>
          <Stack spacing={{ base: "5", sm: "6" }}>
            <Stack spacing="1">
              <Divider />
              <NavButton
                isDisabled={true}
                label="Help"
                icon={FiHelpCircle}
                isActive={activeNav === "Help"}
                onClick={() => handleNavClick("Help", "/help")}
              />
              <NavButton
                label="Settings"
                icon={FiSettings}
                isActive={activeNav === "Settings"}
                onClick={() => handleNavClick("Settings", "/settings")}
              />
            </Stack>
          </Stack>
        </Stack>
      </Flex>
      {!isOpen && (
        <IconButton
          variant="ghost"
          bg="#fff"
          className="rounded-circle shadow"
          aria-label="Toggle Sidebar"
          icon={<LuArrowRightToLine fontSize="0.85rem" />}
          onClick={onToggle}
          position="fixed"
          zIndex={2}
          style={{ top: "117px", left: "32px" }}
          size="sm"
        />
      )}
    </>
  );
}

export default Sidebar;

/* <Box bg="bg-subtle" px="4" py="5" borderRadius="lg">
      <Stack spacing="4">
        <Stack spacing="1">
          <Text fontSize="sm" fontWeight="medium">
            Almost there
          </Text>
          <Text fontSize="sm" color="muted">
            Fill in some more information about you and your person.
          </Text>
        </Stack>
        <Progress
          value={80}
          size="sm"
          aria-label="Profile Update Progress"
        />
        <HStack spacing="3">
          <Button variant="link" size="sm">
            Dismiss
          </Button>
          <Button variant="link" size="sm" colorScheme="blue">
            Update profile
          </Button>
        </HStack>
      </Stack>
    </Box> */

/* <UserProfile
  name="Christoph Winston"
  image="https://tinyurl.com/yhkm2ek8"
  email="chris@chakra-ui.com"
/> */

/* <Button
  className="rounded-circle"
  variant="ghost"
  justifyContent="start"
>
  <Icon as={LuArrowLeftToLine} color="muted" boxSize="5" />
  <Icon as={LuArrowRightToLine} color="muted" boxSize="4" />
</Button> */
