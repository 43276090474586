import { InventoryTransaction } from "./TransactionData";

function InventoryTransactionState() {
  return (
    <div>
      <InventoryTransaction />
    </div>
  );
}

export default InventoryTransactionState;
