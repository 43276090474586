import React from "react";
import AsyncCreatableSelect from "react-select/async-creatable";

interface CustomSelectNameProps {
  label: string;
  options: Array<{ _id: string; label: string; value: string }>;
  isClearable?: boolean;
  onChange: (value: any) => void;
  value: { _id: string; label: string; value: string } | null;
}

const CustomSelectName: React.FC<CustomSelectNameProps> = ({
  label,
  options,
  onChange,
  value,
  isClearable = true,
}) => {
  //   const [field, meta, helpers] = useField(name);

  //   const handleChange = (selectedOption: any) => {
  //     helpers.setValue(selectedOption);
  //   };

  //   console.log(options)
  //   const handleBlur = () => {
  //     helpers.setTouched(true);
  //   };

  return (
    <div className="pt-3">
      <label>{label}</label>
      <AsyncCreatableSelect
        isClearable={isClearable}
        // loadOptions={loadOptions}
        value={value}
        onChange={onChange}
        placeholder="Item Name"
        name={"item_name"}
      />
      {/* {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null} */}
    </div>
  );
};

export default CustomSelectName;
