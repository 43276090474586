import {
  Box,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useGet } from "../../../../Services/API/APIHandlers";
import { materialData } from "../../../../Services/Atoms/MaterialAtoms";
import AddItemForm from "./../Forms/UserModal/AddItemForm";
import PullOutItems from "./../Forms/UserModal/PullOutItems";

function Header() {
  const [items, setItems] = useRecoilState(materialData);

  const [text, setText] = useState<string>("");

  const {
    mutate: getMaterialData,
    isLoading: isMaterialLoading,
    error: materialDataAllError,
    data: allMaterialsData,
    isSuccess: isAllMaterialSuccess,
  } = useGet("api/materials/all");

  const {
    mutate: search,
    isLoading,
    error: materialDataError,
    data: materialsData,
    isSuccess,
  } = useGet("api/materials/search?q=" + text);

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setItems(materialsData.data);
    }
  }, [isSuccess, materialsData]);

  useEffect(() => {
    if (isAllMaterialSuccess) {
      console.log(allMaterialsData.data);
      setItems(allMaterialsData.data);
    }
  }, [isAllMaterialSuccess, allMaterialsData]);

  useEffect(() => {
    if (text.length === 0) {
      getMaterialData();
    }
  }, [text]);

  return (
    <>
      <Box px={{ base: "4", md: "6" }} pt="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="">
              <Link
                className="fs-7 py-1 d-flex flex-row align-items-center me-1"
                to="#"
                role="button"
                id="dropdownMenuLink"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <h4 className="my-0">Material Management</h4>
              </Link>
              <b className="">
                Location:{" "}
                <i className="underline cursor-pointer text-secondary">
                  WAREHOUSE
                </i>
              </b>
            </div>
          </HStack>
          {/* <InputGroup maxW="xs" className="justify-content-end">
            <IconButton
              icon={<IoIosList />}
              variant={"none"}
              className="text-dark fs-5"
              aria-label={"More"}
            />
            <IconButton
              icon={<IoSettingsOutline />}
              variant={"none"}
              className="text-dark fs-5"
              aria-label="Settings"
            />
            <div className="mx-3 border-end"></div>
            <IconButton
              icon={<BsArrowRepeat />}
              variant={"none"}
              aria-label="Refresh"
              className="text-dark fs-5"
            />
            <IconButton
              variant={"none"}
              className="text-dark fs-5"
              aria-label={"More Button"}
              icon={<HiOutlineDotsVertical />}
            />
            <IconButton
              aria-label="Additional Items"
              variant={"none"}
              className="text-dark fs-5"
              icon={<GoChevronDown />}
            />
          </InputGroup> */}
          {/* <InputGroup maxW="xs">
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="muted" boxSize="5" />
          </InputLeftElement>
          <Input placeholder="Search" />
        </InputGroup> */}
          <div className="dropdown">
            <Link
              className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
              to="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Choose Location
            </Link>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a className="dropdown-item" href="#">
                  Site 1
                </a>
              </li>
              <li>
                {" "}
                <a className="dropdown-item" href="#">
                  Site 2
                </a>
              </li>
              <li>
                {" "}
                <a className="dropdown-item" href="#">
                  Warehouse
                </a>
              </li>{" "}
              <li>
                {" "}
                <a className="dropdown-item" href="#">
                  All
                </a>
              </li>
            </ul>
          </div>
        </Stack>
      </Box>
      <hr />

      <Box px={{ base: "4", md: "6" }} pt="3" pb="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {/* <div className="dropdown">
              <Link
                className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Bulk Actions
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div> */}
            {/* <Button colorScheme="gray" size={"sm"} fontWeight={"normal"}>
              Apply
            </Button> */}
            {/* <div className="d-flex flex-row align-items-center">
              <span className="px-2 pe-3">Sort By: </span>
              <div className="dropdown show">
                <a
                  className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Bulk actions
                </a>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </div>
            </div> */}
            {/* <div className="dropdown show">
              <a
                className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Export to CSV
              </a>

              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a className="dropdown-item" href="#">
                  Action
                </a>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
            </div> */}
            <AddItemForm />
            <PullOutItems />
          </HStack>
          <div className="d-flex align-items-center">
            <InputGroup maxW="xs">
              <InputRightElement pointerEvents="none"></InputRightElement>
              <Input
                value={text}
                onChange={(e: any) => setText(e.target.value)}
                placeholder="Search"
              />
              <button
                onClick={(e: any) => search()}
                className="btn btn-primary text-light rounded py-0 px-2 me-2"
              >
                <Icon
                  as={FaSearch}
                  // color="teal"
                  textColor={"#fff"}
                  boxSize="3"
                />
              </button>
            </InputGroup>
            {/* <span className="ps-4 d-flex">
              <button className="btn text-dark p-1">
                <GoChevronLeft fontSize={18} />
              </button>
              <button className="btn text-dark p-1">
                <GoChevronRight fontSize={18} />
              </button>
            </span> */}
          </div>
        </Stack>
      </Box>
    </>
  );
}

export default Header;
