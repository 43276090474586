import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BsArrowRepeat } from "react-icons/bs";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { GoChevronDown, GoChevronLeft, GoChevronRight } from "react-icons/go";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoIosList } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { accessType } from "../../../../Services/Atoms/AuthAtoms";
import { partyPaymentData } from "../../../../Services/Atoms/MaterialAtoms";
import { PartyPayment } from "../../../../Services/Interfaces/UserInterface";
import CreateUser from "../../Users/Forms/UserModal/CreateUser";

const PartyPaymentFormModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  payment?: PartyPayment | null;
  onSave: (payment: PartyPayment) => void;
}> = ({ isOpen, onClose, payment, onSave }) => {
  const [formData, setFormData] = useState<PartyPayment>(
    payment || {
      paymentId: "",
      date: new Date().toISOString(),
      partyName: "",
      item: "",
      quantity: 0,
      rate: 0,
      amount: 0,
      paymentSite: 0,
      paymentHO: 0,
      balance: 0,
      remark: "",
      panNumber: "", // New field
      accountHolderName: "", // New field
      accountNumber: "", // New field
      ifscCode: "", // New field
      bankName: "", // New field
      branch: "", // New field
    }
  );
  const [accessTypeData, setAccessTypeData] = useRecoilState(accessType);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    onSave(formData);
    onClose();
  };

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {payment ? "Edit Party Payment" : "New Party Payment"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Card className="p-3 rounded">
            <Row>
              <Col md={6}>
                <FormControl>
                  <FormLabel>Date</FormLabel>
                  <Input
                    name="date"
                    type={"date"}
                    value={formData.date}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>Party Name</FormLabel>
                  <Input
                    name="partyName"
                    value={formData.partyName}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>Item</FormLabel>
                  <Input
                    name="item"
                    value={formData.item}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>Quantity</FormLabel>
                  <Input
                    name="quantity"
                    type="number"
                    value={formData.quantity}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>Rate</FormLabel>
                  <Input
                    name="rate"
                    type="number"
                    value={formData.rate}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>Amount</FormLabel>
                  <Input
                    name="amount"
                    type="number"
                    value={formData.amount}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>Payment Site</FormLabel>
                  <Input
                    name="paymentSite"
                    type="number"
                    value={formData.paymentSite}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>

              {accessTypeData === "ADMIN" ||
              accessTypeData === "SUPER ADMIN" ||
              accessTypeData === "SUPER_ADMIN" ? (
                <Col md={6}>
                  <FormControl mt={4}>
                    <FormLabel>Payment HO</FormLabel>
                    <Input
                      name="paymentHO"
                      type="number"
                      value={formData.paymentHO}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Col>
              ) : (
                <></>
              )}

              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>Balance</FormLabel>
                  <Input
                    name="balance"
                    type="number"
                    value={formData.balance}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>Remark</FormLabel>
                  <Input
                    name="remark"
                    value={formData.remark}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              {/* New Fields */}
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>PAN Number</FormLabel>
                  <Input
                    name="panNumber"
                    value={formData.panNumber}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>Account Holder Name</FormLabel>
                  <Input
                    name="accountHolderName"
                    value={formData.accountHolderName}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>Account Number</FormLabel>
                  <Input
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>IFSC Code</FormLabel>
                  <Input
                    name="ifscCode"
                    value={formData.ifscCode}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>Bank Name</FormLabel>
                  <Input
                    name="bankName"
                    value={formData.bankName}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
              <Col md={6}>
                <FormControl mt={4}>
                  <FormLabel>Branch</FormLabel>
                  <Input
                    name="branch"
                    value={formData.branch}
                    onChange={handleChange}
                  />
                </FormControl>
              </Col>
            </Row>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DeleteConfirmationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Payment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure you want to delete this payment?</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onConfirm}>
            Delete
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const PartyPaymentTable: React.FC = () => {
  const partyPayments = useRecoilValue(partyPaymentData);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<PartyPayment | null>(
    null
  );

  const handleSave = (payment: PartyPayment) => {
    // Handle save logic (e.g., update state or make API call)
  };

  const handleDelete = () => {
    // Handle delete logic (e.g., update state or make API call)
    setIsDeleteOpen(false);
  };

  return (
    <>
      <>
        <Box px={{ base: "4", md: "6" }} pt="5">
          <Stack
            direction={{ base: "column", md: "row" }}
            justify="space-between"
          >
            <HStack
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Link
                className="fs-7 py-1 d-flex flex-row align-items-center me-1"
                to="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <h4 className="my-0">Party Payment</h4>
              </Link>

              <Link
                to="#"
                className="btn btn-outline-secondary fs-7 py-1 "
                onClick={() => {
                  setSelectedPayment(null);
                  setIsFormOpen(true);
                }}
              >
                New Party Payment
              </Link>
            </HStack>
            <InputGroup maxW="xs" className="justify-content-end">
              <IconButton
                icon={<IoIosList />}
                variant={"none"}
                className="text-dark fs-5"
                aria-label={"More"}
              />
              <IconButton
                icon={<IoSettingsOutline />}
                variant={"none"}
                className="text-dark fs-5"
                aria-label="Settings"
              />
              <div className="mx-3 border-end"></div>
              <IconButton
                icon={<BsArrowRepeat />}
                variant={"none"}
                aria-label="Refresh"
                className="text-dark fs-5"
              />
              <IconButton
                variant={"none"}
                className="text-dark fs-5"
                aria-label={"More Button"}
                icon={<HiOutlineDotsVertical />}
              />
              <IconButton
                aria-label="Additional Items"
                variant={"none"}
                className="text-dark fs-5"
                icon={<GoChevronDown />}
              />
            </InputGroup>
          </Stack>
        </Box>
        <hr />
        <Box px={{ base: "4", md: "6" }} pt="3" pb="5">
          <Stack
            direction={{ base: "column", md: "row" }}
            justify="space-between"
          >
            <HStack
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <div className="dropdown">
                <Link
                  className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Bulk Actions
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <CreateUser />
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </div>

              <Button colorScheme="gray" size={"sm"} fontWeight={"normal"}>
                Apply
              </Button>
              <div className="d-flex flex-row align-items-center">
                <span className="px-2 pe-3">Sort By: </span>
                <div className="dropdown show">
                  <a
                    className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Bulk actions
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </div>
                </div>
              </div>
              <div className="dropdown show">
                <a
                  className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Export to CSV
                </a>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </div>
            </HStack>
            <div className="d-flex align-items-center">
              <InputGroup maxW="xs" padding={0}>
                <Input
                  placeholder="Search"
                  size="sm"
                  borderRadius={6}
                  width={"auto"}
                />
              </InputGroup>
              <span className="ps-4 d-flex">
                <button className="btn text-dark p-1">
                  <GoChevronLeft fontSize={18} />
                </button>
                <button className="btn text-dark p-1">
                  <GoChevronRight fontSize={18} />
                </button>
              </span>
            </div>
          </Stack>
        </Box>
      </>
      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Party Name</Th>
            <Th>Item</Th>
            <Th>Quantity</Th>
            <Th>Rate</Th>
            <Th>Amount</Th>
            <Th>Payment Site</Th>
            <Th>Payment HO</Th>
            <Th>Balance</Th>
            <Th>Remark</Th>
            {/* <Th>PAN Number</Th>
            <Th>Account Holder Name</Th>
            <Th>Account Number</Th>
            <Th>IFSC Code</Th>
            <Th>Bank Name</Th>
            <Th>Branch</Th>
            <Th>Actions</Th> */}
          </Tr>
        </Thead>
        <Tbody>
          {partyPayments &&
            partyPayments.map((payment) => (
              <Tr key={payment.paymentId}>
                <Td>{payment.date}</Td>
                <Td>{payment.partyName}</Td>
                <Td>{payment.item}</Td>
                <Td>{payment.quantity}</Td>
                <Td>{payment.rate}</Td>
                <Td>{payment.amount}</Td>
                <Td>{payment.paymentSite}</Td>
                <Td>{payment.paymentHO}</Td>
                <Td>{payment.balance}</Td>
                <Td>{payment.remark}</Td>
                {/* <Td>{payment.panNumber}</Td>
                <Td>{payment.accountHolderName}</Td>
                <Td>{payment.accountNumber}</Td>
                <Td>{payment.ifscCode}</Td>
                <Td>{payment.bankName}</Td>
                <Td>{payment.branch}</Td> */}
                <Td>
                  <HStack>
                    <IconButton
                      icon={<FiEdit />}
                      aria-label="Edit"
                      onClick={() => {
                        setSelectedPayment(payment);
                        setIsFormOpen(true);
                      }}
                    />
                    <IconButton
                      icon={<FiTrash2 />}
                      aria-label="Delete"
                      onClick={() => {
                        setSelectedPayment(payment);
                        setIsDeleteOpen(true);
                      }}
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>

      <PartyPaymentFormModal
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        payment={selectedPayment}
        onSave={handleSave}
      />

      <DeleteConfirmationModal
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default PartyPaymentTable;
//  ```

// In the given code snippet, the `PartyPaymentTable` component is a React component that displays a table of party payments. The component uses the `Box`, `Stack`, `Table`, `Thead`, `Tbody`, `InputGroup`, `Input`, `IconButton`, `FiEdit`, `FiTrash2`, `CreateUser`, and `DeleteConfirmationModal` components from the Chakra UI library.
