import { FaMoneyBillTrendUp, FaPeopleGroup } from "react-icons/fa6";
import { LiaShippingFastSolid } from "react-icons/lia";
import { MdOutlineAccountTree } from "react-icons/md";

export const Departments = (type: string | undefined) => {
  if (type === "MATERIAL_MANAGEMENT") {
    return {
      name: "MATERIAL MANAGEMENT",
      backgroundImage:
        "https://res.cloudinary.com/dfhnhykia/image/upload/v1716138612/bpc/pjzzhhieczibpczfpmqd.webp",
      icon: LiaShippingFastSolid,
      path: "MATERIAL_MANAGEMENT",
    };
  }

  if (type === "ACCOUNTS") {
    return {
      name: "ACCOUNTS",
      backgroundImage:
        "https://res.cloudinary.com/dfhnhykia/image/upload/v1716174470/bpc/b0pptuypasv14ebll65v.jpg",
      icon: MdOutlineAccountTree,
      path: "ACCOUNTS",
    };
  }

  if (type === "FINANCE") {
    return {
      name: "FINANCE",
      backgroundImage:
        "https://res.cloudinary.com/dfhnhykia/image/upload/v1716174510/bpc/tm7wh4hnloyiahrwerfj.webp",
      icon: FaMoneyBillTrendUp,
      path: "FINANCE",
    };
  }

  if (type === "HR") {
    return {
      name: "HUMAN RESOURCE",
      backgroundImage:
        "https://res.cloudinary.com/dfhnhykia/image/upload/v1716174562/bpc/wq2mscioklqvzczivbyy.jpg",
      icon: FaPeopleGroup,
      path: "HR",
    };
  }

  return {
      name: "DASHBOARD",
      backgroundImage:
        "https://res.cloudinary.com/dfhnhykia/image/upload/v1716174510/bpc/tm7wh4hnloyiahrwerfj.webp",
      icon: FaMoneyBillTrendUp,
    };;
};
