import {
  Box,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Icon,
  Image,
  Input,
  useToast,
} from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as hotToast from "react-hot-toast";
import { FiUpload } from "react-icons/fi";
import * as Yup from "yup";
import { useGet, usePut } from "../../../Services/API/APIHandlers";
import { BASE_URL } from "../../../Services/Config";
import ProfilePictureCrop from "./ProfilePictureCrop"; // Adjust the path as per your file structure
import Signature from "./Signature"; // Adjust the path as per your file structure
import { useRecoilState } from "recoil";
import { profilePhotoUrl } from "../../../Services/Atoms/AuthAtoms";

// Validation Schema
const ProfileSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
  password: Yup.string().required("Password is required"),
  department: Yup.string().required("Department is required"),
  access_type: Yup.string().required("Access type is required"),
  access_to_department: Yup.array()
    .of(Yup.string())
    .required("Access to department is required"),
});

const Settings: React.FC = () => {
  const [profilePhoto, setProfilePhoto] = useState<File | null>(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | undefined>(
    undefined
  );
  const [signature, setSignature] = useState<File | null>(null);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const toast = useToast();

  const userid = localStorage.getItem("userid");
  const [profilePicUrlActive, setProfilePicUrlActive] =
    useRecoilState(profilePhotoUrl);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      department: "",
      profilePicUrl: "",
      signatureUrl: "",
      access_type: "",
      access_to_department: [] as string[],
    },
    validationSchema: ProfileSchema,
    onSubmit: (values) => {
      // Submit form values
      console.log(values);
      toast({
        title: "Profile updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const onDropProfilePhoto = (acceptedFiles: File[]) => {
    setProfilePhoto(acceptedFiles[0]);
    setIsCropModalOpen(true);
  };

  const profilePhotoDropzone = useDropzone({
    onDrop: onDropProfilePhoto,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    multiple: false,
  });

  const onDropSignatureFile = (acceptedFiles: File[]) => {
    setSignature(acceptedFiles[0]);
    setIsSignatureModalOpen(false); // Close the signature modal after selecting a file
  };

  const signatureDropzone = useDropzone({
    onDrop: onDropSignatureFile,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    multiple: false,
  });

  const handleCropModalClose = () => {
    setIsCropModalOpen(false);
  };

  const {
    mutate: getUser,
    data: getUserData,
    isLoading: getIsLoading,
    isSuccess: getIsSuccess,
    error: getErrorData,
  } = useGet("api/users/" + userid);

  const handleCropSave = (blob: Blob) => {
    // Convert blob to a File object
    const file = new File([blob], "cropped_image.jpg", { type: "image/jpeg" });

    // Create a URL for the cropped image
    const croppedImageUrl = URL.createObjectURL(file);

    // Update state with the new file and URL
    setProfilePhoto(file);
    setCroppedImage(croppedImageUrl);

    // Close the crop modal
    setIsCropModalOpen(false);
  };

  const handleSignatureModalClose = () => {
    setIsSignatureModalOpen(false);
  };

  const handleSignatureModalOpen = () => {
    setIsSignatureModalOpen(true);
  };

  const {
    mutate: updateImage,
    isSuccess: isUpdateImageSuccess,
    isLoading: isUpdateImageLoading,
    data: isUpdateImageData,
    error: isUpdateImageError,
  } = usePut("api/users/" + userid);

  useEffect(() => {
    if (isUpdateImageSuccess) {
      setProfilePicUrlActive(isUpdateImageData.data.profilePicUrl);
    }
  }, [isUpdateImageSuccess]);

  useEffect(() => {
    if (getErrorData instanceof AxiosError) {
      // Call a toast
      hotToast.toast.error(getErrorData.response?.data.message);
    }
  }, [getErrorData]);

  useEffect(() => {
    if (getIsSuccess) {
      formik.setValues(getUserData.data);
      setCroppedImage(getUserData.data.profilePicUrl);

      if (getUserData.data.signatureUrl) {
        fetch(getUserData.data.signatureUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "signature.jpg", {
              type: blob.type,
            });
            setSignature(file);
          })
          .catch((error) => {
            console.error("Error fetching signature:", error);
          });
      }
    }
  }, [getIsSuccess, getUserData]);

  useEffect(() => {
    getUser();
  }, []);

  const handleUpload = async () => {
    if (!signature) {
      hotToast.toast.error("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    formData.append("image", signature); // Make sure the field name matches your backend

    try {
      const response = await axios.post(
        BASE_URL + "api/upload-image", // Update the URL to match your backend
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      updateImage({ ...formik.values, signatureUrl: response.data.url });

      hotToast.toast.success("Upload Successful");
    } catch (error) {
      hotToast.toast.error("Please select a file to upload");

      console.error(error);
    }
  };

  useEffect(() => {
    if (signature) {
      handleUpload();
    }
  }, [signature]);

  return (
    <>
      <Card maxW="5xl" mx="auto" mt={8} p={6} boxShadow={"xl"}>
        <CardHeader>
          <Heading as="h1" size="lg" textAlign="start">
            Settings
          </Heading>
        </CardHeader>
        <CardBody>
          <form onSubmit={formik.handleSubmit}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Box>
                <Box
                  {...profilePhotoDropzone.getRootProps()}
                  p={4}
                  borderWidth={2}
                  borderRadius="full"
                  borderColor="gray.300"
                  borderStyle="dashed"
                  height={300}
                  width={300}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  textAlign="center"
                  cursor="pointer"
                >
                  <input {...profilePhotoDropzone.getInputProps()} />
                  {croppedImage ? (
                    <Image
                      src={formik.values.profilePicUrl || croppedImage}
                      alt="Profile Photo"
                      borderRadius="full"
                    />
                  ) : (
                    <Icon as={FiUpload} boxSize={6} color="gray.400" />
                  )}
                  {!croppedImage && (
                    <p>Drag 'n' drop profile photo here, or click to select</p>
                  )}
                </Box>
              </Box>
              <Box>
                <FormLabel>Signature</FormLabel>
                <Box
                  {...signatureDropzone.getRootProps()}
                  p={4}
                  borderWidth={2}
                  borderRadius="md"
                  borderColor="gray.300"
                  borderStyle="dashed"
                  textAlign="center"
                  cursor="pointer"
                  onClick={handleSignatureModalOpen}
                >
                  <input {...signatureDropzone.getInputProps()} />
                  {signature ? (
                    <Box>
                      <Image
                        src={
                          formik.values.signatureUrl ||
                          URL.createObjectURL(signature)
                        }
                        alt="Signature"
                      />
                    </Box>
                  ) : (
                    <Box>
                      <Icon as={FiUpload} boxSize={6} color="gray.400" />
                      <p>Drag 'n' drop signature here, or click to select</p>
                    </Box>
                  )}
                </Box>
              </Box>
            </div>

            <h2>Personal Details</h2>
            <Grid
              templateColumns="repeat(2, 1fr)"
              gap={6}
              paddingBottom={4}
              paddingTop={5}
              w="full"
            >
              <FormControl
                isInvalid={
                  !!formik.errors.first_name && formik.touched.first_name
                }
              >
                <FormLabel htmlFor="first_name">First Name</FormLabel>
                <Input
                  id="first_name"
                  name="first_name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                />
              </FormControl>
              <FormControl
                isInvalid={
                  !!formik.errors.last_name && formik.touched.last_name
                }
              >
                <FormLabel htmlFor="last_name">Last Name</FormLabel>
                <Input
                  id="last_name"
                  name="last_name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                />
              </FormControl>
              <FormControl
                isInvalid={!!formik.errors.email && formik.touched.email}
              >
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </FormControl>
              <FormControl
                isInvalid={!!formik.errors.phone && formik.touched.phone}
              >
                <FormLabel htmlFor="phone">Phone</FormLabel>
                <Input
                  id="phone"
                  name="phone"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
              </FormControl>
              <FormControl
                isInvalid={!!formik.errors.password && formik.touched.password}
              >
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
              </FormControl>
              <FormControl
                isInvalid={
                  !!formik.errors.department && formik.touched.department
                }
              >
                <FormLabel htmlFor="department">Department</FormLabel>
                <Input
                  id="department"
                  name="department"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.department}
                />
              </FormControl>
              <FormControl
                isInvalid={
                  !!formik.errors.access_type && formik.touched.access_type
                }
              >
                <FormLabel htmlFor="access_type">Access Type</FormLabel>
                <Input
                  id="access_type"
                  name="access_type"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.access_type}
                />
              </FormControl>
              <FormControl
                isInvalid={
                  !!formik.errors.access_to_department &&
                  formik.touched.access_to_department
                }
              >
                <FormLabel htmlFor="access_to_department">
                  Access to Department
                </FormLabel>
                <Input
                  id="access_to_department"
                  name="access_to_department"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.access_to_department.join(", ")}
                />
              </FormControl>
            </Grid>
            {/* <Button colorScheme="teal" width="full" mt={4}>
              Request Update
            </Button> */}
          </form>

          {/* Profile Picture Crop Modal */}
          <ProfilePictureCrop
            isOpen={isCropModalOpen}
            onClose={handleCropModalClose}
            onCrop={handleCropSave}
            imageSrc={profilePhoto ? URL.createObjectURL(profilePhoto) : ""}
            data={formik.values}
            userid={userid}
          />

          {/* Signature Modal */}
          <Signature
            isOpen={isSignatureModalOpen}
            onClose={handleSignatureModalClose}
            onDropSignature={onDropSignatureFile}
            signature={signature}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default Settings;
