import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App/App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./Utils/Bootstrap/bootstrap.min.css";
import { RecoilRoot } from 'recoil';
import RecoilNexus from "recoil-nexus";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <RecoilNexus />
        <App />
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
