import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BASE_URL } from "../../../../Services/Config";

type FileUploadProps = {
  challanUrl: string | null;
  setChallanUrl: React.Dispatch<React.SetStateAction<string | null>>;
};

const FileUpload: React.FC<FileUploadProps> = ({
  challanUrl,
  setChallanUrl,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    formData.append("image", file); // Make sure the field name matches your backend

    try {
      const response = await axios.post(
        BASE_URL + "api/upload-image", // Update the URL to match your backend
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setChallanUrl(response.data.url);
      setError(null);
    } catch (error) {
      setError("Error uploading file");
      console.error(error);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setError(null);

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const selectedFile = files[0];
      setFile(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    // Cleanup the preview URL when the component is unmounted or the file changes
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  return (
    <div>
      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleClick}
        style={{
          border: "2px dashed #cccccc",
          padding: "20px",
          borderRadius: "8px",
          marginBottom: "20px",
          cursor: "pointer",
          textAlign: "center",
        }}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        {previewUrl ? (
          <img
            src={previewUrl}
            alt="Preview"
            style={{ maxWidth: "100px", maxHeight: "100px" }}
          />
        ) : (
          <p>Drag & Drop or Click to Upload</p>
        )}
      </div>
      {challanUrl && (
        <div>
          <a href={challanUrl} target="_blank" rel="noopener noreferrer">
            View Uploaded File
          </a>
        </div>
      )}
      {error && <div>{error}</div>}
    </div>
  );
};

export default FileUpload;
