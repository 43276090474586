import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
// import { MemberTable } from "./MemberTable";
import Header from "./Header";
import LabourPaymentTable from "./LabourPaymentTable";

export const LabourPaymentModule = () => {
  const isMobile = useBreakpointValue({ base: false, md: false });

  return (
    <>
      <Box
        bg="bg-surface"
        boxShadow={{ base: "none", md: useColorModeValue("sm", "sm-dark") }}
        borderRadius={useBreakpointValue({ base: "none", md: "lg" })}
      >
        <Header />
        <Stack spacing="5">
          <Box overflowX="auto">
            <LabourPaymentTable />
          </Box>
          <Box px={{ base: "4", md: "6" }} pb="5">
            <HStack spacing="3" justify="space-between">
              {!isMobile && (
                <Text color="muted" fontSize="sm">
                  Showing 1 to 5 of 42 results
                </Text>
              )}
              <ButtonGroup
                spacing="3"
                justifyContent="space-between"
                width={{ base: "full", md: "auto" }}
                variant="secondary"
              >
                <Button>Previous</Button>
                <Button>Next</Button>
              </ButtonGroup>
            </HStack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
