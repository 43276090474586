import Navbar from "../../components/Navbar";
import { Sites } from "./SitesTable";


function SiteSection() {
  return (
    <div>
      <Sites />
    </div>
  );
}

export default SiteSection;
