import { useState } from "react";
import { InventoryTable } from "./InventoryTable";

function Inventory() {

  return (
    <div>
      <InventoryTable />
    </div>
  );
}

export default Inventory;
