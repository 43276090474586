import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { FiEdit2 } from "react-icons/fi";
import Select from "react-select";
import { useRecoilState } from "recoil";
import * as yup from "yup";
import { usePut } from "../../../../../Services/API/APIHandlers";
import { materialData } from "../../../../../Services/Atoms/MaterialAtoms";
import CustomSelectName from "../../Components/CustomSelectName";

interface SelectOption {
  value: string | number;
  label: string | number;
}

const blockOptions: SelectOption[] = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "AT", label: "AT" },
  { value: "ABT", label: "ABT" },
  { value: "BCT", label: "BCT" },
  { value: "CT1", label: "CT1" },
  { value: "CT2", label: "CT2" },
  { value: "DT1", label: "DT1" },
  { value: "DT2", label: "DT2" },
];

const columnOptions: SelectOption[] = Array.from({ length: 16 }, (v, k) => ({
  value: k + 1,
  label: k + 1,
}));

const cellOptions: SelectOption[] = [
  { value: "001", label: "001" },
  { value: "002", label: "002" },
  { value: "003", label: "003" },
  { value: "004", label: "004" },
  { value: "005", label: "005" },
];

const validationSchema = yup.object().shape({
  item_name: yup.object().required("Item Name is required").nullable(),
  lv: yup.number().required("LV is required").nullable(),
  lv_unit: yup.object().required("LV Unit is required").nullable(),
  quantity: yup.number().required("Quantity is required").nullable(),
  qt_unit: yup.object().required("Quantity Unit is required").nullable(),
  block: yup.object().required("Block is required").nullable(),
  column: yup.object().required("Column is required").nullable(),
  cell: yup.object().required("Cell is required").nullable(),
});

type formValues = {
  item_name: { _id: string; label: string; value: string } | null;
  brand_name: string;
  lv: number | null;
  lv_unit: string | null | SelectOption;
  quantity: number | null;
  qt_unit: string | null | SelectOption;
  block: string | null | SelectOption;
  column: string | null | SelectOption;
  cell: string | null | SelectOption;
};

type formValuesTable = {
  _id: string;
  item_name: null | { _id: string; label: string; value: string };
  brand_name: string;
  lv: number | null;
  lv_unit: null | SelectOption;
  quantity: number | null;
  qt_unit: null | SelectOption;
  block: null | SelectOption;
  column: null | SelectOption;
  cell: null | SelectOption;
};

const lvUnitOptions: SelectOption[] = [
  { value: "inch", label: "inch" },
  { value: "cm", label: "cm" },
  { value: "m", label: "m" },
  { value: "ft", label: "ft" },
];

const qtUnitOptions: SelectOption[] = [
  { value: "kg", label: "kg" },
  { value: "litre", label: "litre" },
  { value: "gm", label: "gm" },
];

type editItemType = {
  data: formValuesTable;
};

const EditItem = (props: editItemType) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [materialItemData, setMaterialItemData] = useRecoilState(materialData);

  const formik = useFormik({
    initialValues: props.data,
    validationSchema,
    onSubmit: (values) => {
      updateMaterialItems({
        ...values,
        item_name: {
          label: values.item_name?.label,
          value: values.item_name?.value,
        },
      });
      onClose();
    },
  });

  const {
    mutate: updateMaterialItems,
    isLoading,
    error: materialDataError,
    data: materialsData,
    isSuccess,
  } = usePut("api/materials/" + props.data._id);

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setMaterialItemData(
        materialItemData &&
          materialItemData.map((item: any, index: number) => {
            if (item._id === materialsData.data._id) return materialsData.data;
            else return item;
          })
      );
    }
  }, [isSuccess, materialsData]);

  return (
    <>
      <IconButton
        className="rounded-circle"
        icon={<FiEdit2 fontSize="1rem" />}
        variant="ghost"
        aria-label="Update User Info"
        onClick={onOpen}
      />

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size={"xl"}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody style={{ padding: "20px" }}>
            <ModalCloseButton />
            <h4 className="text-center mb-3">Update an Item</h4>

            <Form onSubmit={formik.handleSubmit}>
              <Card className="rounded-5 mt-3 border-muted">
                <Card.Body>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="pt-3" controlId="item_name">
                        <Form.Label>Item Name</Form.Label>
                        {/* <Form.Control
                          type="text"
                          placeholder="Item Name"
                          {...formik.getFieldProps("item_name")}
                          isInvalid={
                            formik.touched.item_name &&
                            !!formik.errors.item_name
                          }
                        /> */}
                        <CustomSelectName
                          onChange={formik.handleChange}
                          value={formik.values.item_name}
                          label="Item Name"
                          options={
                            props.data.item_name ? [props.data.item_name] : []
                          }
                          isClearable
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.item_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="pt-3" controlId="lv">
                        <Form.Label>Brand Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Brand"
                          {...formik.getFieldProps("brand_name")}
                          isInvalid={
                            formik.touched.brand_name &&
                            !!formik.errors.brand_name
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.brand_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="lv">
                        <Form.Label>LV</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="LV"
                          {...formik.getFieldProps("lv")}
                          isInvalid={formik.touched.lv && !!formik.errors.lv}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors?.lv}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="lv_unit">
                        <Form.Label>LV Unit</Form.Label>
                        <Select
                          options={lvUnitOptions}
                          value={formik.values.lv_unit}
                          onChange={(option) =>
                            formik.setFieldValue("lv_unit", option)
                          }
                          onBlur={() => formik.setFieldTouched("lv_unit", true)}
                        />
                        {formik.touched.lv_unit && formik.errors.lv_unit && (
                          <div className="invalid-feedback d-block">
                            {formik.errors.lv_unit}
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="quantity">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Quantity"
                          {...formik.getFieldProps("quantity")}
                          isInvalid={
                            formik.touched.quantity && !!formik.errors.quantity
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.quantity}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="qt_unit">
                        <Form.Label>Quantity Unit</Form.Label>
                        <Select
                          options={qtUnitOptions}
                          value={formik.values.qt_unit}
                          onChange={(option) =>
                            formik.setFieldValue("qt_unit", option)
                          }
                          onBlur={() => formik.setFieldTouched("qt_unit", true)}
                        />
                        {formik.touched.qt_unit && formik.errors.qt_unit && (
                          <div className="invalid-feedback d-block">
                            {formik.errors.qt_unit}
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="block">
                        <Form.Label>Block</Form.Label>
                        <Select
                          options={blockOptions}
                          value={formik.values.block}
                          onChange={(option) =>
                            formik.setFieldValue("block", option)
                          }
                          onBlur={() => formik.setFieldTouched("block", true)}
                        />
                        {formik.touched.block && formik.errors.block && (
                          <div className="invalid-feedback d-block">
                            {formik.errors.block}
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="column">
                        <Form.Label>Column</Form.Label>
                        <Select
                          options={columnOptions}
                          value={formik.values.column}
                          onChange={(option) =>
                            formik.setFieldValue("column", option)
                          }
                          onBlur={() => formik.setFieldTouched("column", true)}
                        />
                        {formik.touched.column && formik.errors.column && (
                          <div className="invalid-feedback d-block">
                            {formik.errors.column}
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="cell">
                        <Form.Label>Cell</Form.Label>
                        <Select
                          options={cellOptions}
                          value={formik.values.cell}
                          onChange={(option) =>
                            formik.setFieldValue("cell", option)
                          }
                          onBlur={() => formik.setFieldTouched("cell", true)}
                        />
                        {formik.touched.cell && formik.errors.cell && (
                          <div className="invalid-feedback d-block">
                            {formik.errors.cell}
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Button
                        className="btn btn-primary mt-4 d-block text-uppercase fw-bold w-100"
                        type="submit"
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditItem;
