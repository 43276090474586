import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";

import { Button, useBreakpointValue } from "@chakra-ui/react";
import toast from "react-hot-toast";
import { FaMicrosoft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";
import { usePost } from "../../Services/API/APIHandlers";
import {
  accessType,
  isAuthenticated,
  localTokenSet,
  profilePhotoUrl,
} from "../../Services/Atoms/AuthAtoms";
import { LOGIN_SUCCESS } from "../../Services/Flags/AuthFlags";
import { LoginFormValues } from "../../Services/Interfaces/UserInterface";
import { LocalTokenSetType } from "../../Services/Types/AuthTypes";
import { LoginValidation } from "../../Services/Validations/AuthValidations";
import { BASE_URL } from "../../Services/Config";

function Login() {
  const [loginError, setLoginError] = useState<string>("");
  // const [loggedIn, setLoggedIn] = useState<boolean>(true);
  const navigate = useNavigate();

  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });

  const [localTokenData, setLocalTokenData] = useRecoilState(localTokenSet);
  const [accessTypeData, setAccessTypeData] = useRecoilState(accessType);
  const [profilePicUrlActive, setProfilePicUrlActive] =
    useRecoilState(profilePhotoUrl);

  const {
    mutate: login,
    isLoading,
    error: loginErrorFetch,
    data: loginData,
    isSuccess,
  } = usePost("api/login/");

  const formik = useFormik<LoginFormValues>({
    initialValues: {
      employeeID: "",
      password: "",
    },
    validationSchema: LoginValidation,
    onSubmit: async (values) => {
      // console.log("Form submitted with values:", values);

      login(values);
    },
    // onReset: (values) => {
    //   // Your form reset logic here
    // },
  });
  useEffect(() => {
    if (loginErrorFetch instanceof AxiosError) {
      // Call a toast
      toast.error(loginErrorFetch?.response?.data.message);
    }
  }, [loginErrorFetch]);

  useEffect(() => {
    if (isSuccess) {
      let tokenData: LocalTokenSetType = {
        accessToken: loginData.data.access,
        refreshToken: loginData.data.refresh,
      };

      // Call a toast
      toast.success(LOGIN_SUCCESS);
      setLocalTokenData(tokenData);
      setRecoil(isAuthenticated, true);
      localStorage.setItem("token", JSON.stringify(tokenData));
      localStorage.setItem("department", loginData.data.department);
      localStorage.setItem("userid", loginData.data._id);
      localStorage.setItem("profilePicUrl", loginData.data.profilePicUrl);
      setProfilePicUrlActive(profilePicUrlActive);
      localStorage.setItem("access_type", loginData.data.access_type);
      setAccessTypeData(loginData.data.access_type);
      navigate("/department/" + loginData.data.department);
    }
  }, [isSuccess, loginData, setLocalTokenData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isAuth = useRecoilValue(isAuthenticated);

  return (
    <div
      style={{
        backgroundImage: `url(${"https://res.cloudinary.com/dfhnhykia/image/upload/v1720157144/bpc/ondrghouln8nx1tkck50.jpg"})`,
        backgroundSize: "cover",
        backgroundRepeat: "norepeat",
        backgroundPosition: "center center",
        backgroundBlendMode: "lighten",
        height: "100vh",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div
              style={{
                borderBottomLeftRadius: 25,
                borderBottomRightRadius: 25,
              }}
              className="d-flex pt-5 border shadow-lg bg-white flex-row justify-content-center"
            >
              <img
                src={
                  "https://res.cloudinary.com/dfhnhykia/image/upload/v1720158048/bpc/eztd9w5ndj3symrm04kx.png"
                }
                width={384}
                alt=""
              />
            </div>
            <div className="mt-5">
              <Card className="rounded-5 p-2 shadow-lg border-muted">
                <Card.Body>
                  <h4 className="text-center mb-4">Sign in to Your Account</h4>
                  <Form onSubmit={formik.handleSubmit}>
                    <Form.Group controlId="employeeID">
                      <Form.Label>Employee ID</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Employee ID"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.employeeID}
                        isInvalid={
                          formik.touched.employeeID &&
                          !!formik.errors.employeeID
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.employeeID}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="pt-3" controlId="password">
                      <div className="d-flex flex-row justify-content-between">
                        <Form.Label>Password</Form.Label>
                        <a href="#" className="fs-6 fw-medium text-primary">
                          Forgot password ?
                        </a>
                      </div>
                      <Form.Control
                        type="password"
                        placeholder="Enter Your Password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        isInvalid={
                          formik.touched.password && !!formik.errors.password
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {loginError && (
                      <div style={{ color: "red" }}>{loginError}</div>
                    )}

                    {/* <div className="d-flex justify-content-center pt-3">
                      <div className="form-check form-check-sm mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input rounded-1"
                          id="logged_in"
                          onChange={(e: any) => setLoggedIn(e.target.value)}
                          checked={loggedIn}
                        />
                        <label
                          className="form-check-label text-muted fs-6"
                          htmlFor="logged_in"
                        >
                          Keep me logged in
                        </label>
                      </div>
                    </div> */}

                    <Button
                      type="submit"
                      className="btn btn-primary d-block text-uppercase mt-3 w-100 fw-bold"
                    >
                      Login
                    </Button>

                    <Button
                      leftIcon={<FaMicrosoft />}
                      variant="outline"
                      colorScheme="green"
                      marginTop={10}
                      size={buttonSize}
                      borderColor="green.500"
                      onClick={() => {
                        window.location.href = BASE_URL + "auth";
                      }}
                      width={"100%"}
                      borderWidth={2}
                      _hover={{ bg: "green.50", borderColor: "green.600" }}
                      _active={{ bg: "green.100", borderColor: "green.700" }}
                      p={6}
                    >
                      Sign in with Microsoft Account
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <div className="position-fixed  shadow-lg bg-white py-4  bottom-0 w-100">
        <footer className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-8 text-center fs-8">
              <p className="footer-text pb-0">
                <span className="copy-text">
                  Webspace IT Solution Pvt. Ltd. © 2023 All rights reserved.
                  <br />
                  <a href="#" className="text-primary" target="_blank">
                    Privacy Policy
                  </a>{" "}
                  <span className="footer-link-sep">|</span>{" "}
                  <a href="#" className="text-primary" target="_blank">
                    T&amp;C
                  </a>{" "}
                  <span className="footer-link-sep">|</span>{" "}
                  <a href="#" className="text-primary" target="_blank">
                    System Status
                  </a>{" "}
                </span>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Login;
