import * as Yup from "yup";
export const UserSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  password: Yup.string().required("Password is required"),
  department: Yup.string().required("Department is required"),
  access_type: Yup.string().required("Access type is required"),
  access_to_department: Yup.array().min(1, "Select at least one department").required("Required"),
});
