import {
  HStack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { useGet } from "../../../../Services/API/APIHandlers";
import { materialData } from "../../../../Services/Atoms/MaterialAtoms";
import { formValuesTable } from "../../../../Services/Types/MaterialType";
import EditItem from "../Forms/UserModal/UpdateItem";
import DeleteMaterial from "./../Forms/UserModal/DeleteMaterial";
import Header from "./Header";

export const ItemsTable = (props: TableProps) => {
  const [items, setItems] = useRecoilState(materialData);
  const [dataSite, setDataSite] = useState(true);

  const {
    mutate: getMaterialData,
    isLoading,
    error: materialDataError,
    data: materialsData,
    isSuccess,
  } = useGet("api/materials/all");

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setItems(materialsData.data);
    }
  }, [isSuccess, materialsData]);

  useEffect(() => {
    getMaterialData();
    window.scrollTo(0, 0);
  }, []);

  // Define the fields for CSV export
  const csvFields = [
    "Item Name",
    "Brand Name",
    "Length/Volume",
    "L/V Unit",
    "Quantity",
    "Qty Unit",
    "Position",
  ];

  // Format the data for CSV export
  const csvData = items?.map((item) => ({
    "Item Name": item.item_name?.value,
    "Brand Name": item.brand_name,
    "Length/Volume": item.lv,
    "L/V Unit": item.lv_unit?.label,
    Quantity: item.quantity,
    "Qty Unit": item.qt_unit?.label,
    Position:
      (item.block?.label ? item.block?.label : "") +
      item.column?.label +
      item.cell?.label,
  }));

  return (
    <>
      <Header csvData={csvData} csvFields={csvFields} />
      <Box overflowY="auto" maxHeight="80vh">
        <Table {...props}>
          <Thead position="sticky" top="0" bg="gray.100">
            <Tr>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">S. No.</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Item Name</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Brand Name</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Length/Volume</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">L/V Unit</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Quantity</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Qty Unit</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Position</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody fontSize={"15px"}>
            {items ? (
              items.map((item: formValuesTable, index: number) => {
                return (
                  <Tr key={index}>
                    <Td>
                      <Text className="mb-0" color="muted">
                        {index + 1}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="muted">
                        {item.item_name?.value}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="muted">
                        {item.brand_name}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="muted">
                        {item.lv}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="#777">
                        {item.lv_unit?.label}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="#777">
                        {item.quantity ? item?.quantity : ""}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="#777">
                        {item.qt_unit?.label}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="#777">
                        {(item.block?.label ? item.block?.label : "") +
                          item.column?.label +
                          item.cell?.label}
                      </Text>
                    </Td>

                    <Td>
                      <HStack spacing="1">
                        <EditItem data={item} />
                        <DeleteMaterial data={item} />
                      </HStack>
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <>Loading Data</>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
