import {
  Box,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useGet } from "../../../../Services/API/APIHandlers";
import { materialData } from "../../../../Services/Atoms/MaterialAtoms";
import SiteSelect from "../../../components/SitesSelect";
import ExportCSV from "./../../../components/CSVExport/ExportToCSV";
import AddItemForm from "./../Forms/UserModal/AddItemForm";
import PullOutItems from "./../Forms/UserModal/PullOutItems";

type propType = {
  csvData: Record<string, any>[] | undefined;
  csvFields: string[];
};

interface Site {
  _id: string;
  project_name: string;
  project_id: string;
  address: string;
  department: string;
  start_date: string;
  end_date?: string;
}

function Header(props: propType) {
  const [items, setItems] = useRecoilState(materialData);

  const [text, setText] = useState<string>("");

  const {
    mutate: getMaterialData,
    isLoading: isMaterialLoading,
    error: materialDataAllError,
    data: allMaterialsData,
    isSuccess: isAllMaterialSuccess,
  } = useGet("api/materials/all");

  const {
    mutate: search,
    isLoading,
    error: materialDataError,
    data: materialsData,
    isSuccess,
  } = useGet("api/materials/search?q=" + text);

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setItems(materialsData.data);
    }
  }, [isSuccess, materialsData]);

  useEffect(() => {
    if (isAllMaterialSuccess) {
      setItems(allMaterialsData.data);
    }
  }, [isAllMaterialSuccess, allMaterialsData]);

  useEffect(() => {
    if (text.length === 0) {
      getMaterialData();
    }
  }, [text]);

  const [selectedSite, setSelectedSite] = useState<Site | null>({
    _id: "66be96fe3db19f197a7d031d",
    project_name: "WAREHOUSE",
    project_id: "BPC001",
    address: "Thakurgaon, Ranchi",
    department: "MATERIAL_MANAGEMENT",
    start_date: "2024-08-06T00:00:00.000Z",
  });

  const handleSiteChange = (site: Site | null) => {
    setSelectedSite(site);
    // Perform other actions with the selected site if needed
  };

  return (
    <div className="navbar ">
      <Box px={{ base: "4", md: "6" }} pt="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="">
              <Link
                className="fs-7 py-1 d-flex flex-row align-items-center me-1"
                to="#"
                role="button"
                id="dropdownMenuLink"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <h4 className="my-0">Material Management</h4>
              </Link>
              <b className="">
                Location:{" "}
                <i className="underline cursor-pointer text-secondary">
                  {selectedSite?.project_name}
                  {/* SITE: BPCS001: NIRSA DHANBAD */}
                </i>
              </b>
              <SiteSelect onSiteChange={handleSiteChange} />
            </div>
          </HStack>
          {/* <InputGroup maxW="xs" className="justify-content-end">
            <IconButton
              icon={<IoIosList />}
              variant={"none"}
              className="text-dark fs-5"
              aria-label={"More"}
            />
            <IconButton
              icon={<IoSettingsOutline />}
              variant={"none"}
              className="text-dark fs-5"
              aria-label="Settings"
            />
            <div className="mx-3 border-end"></div>
            <IconButton
              icon={<BsArrowRepeat />}
              variant={"none"}
              aria-label="Refresh"
              className="text-dark fs-5"
            />
            <IconButton
              variant={"none"}
              className="text-dark fs-5"
              aria-label={"More Button"}
              icon={<HiOutlineDotsVertical />}
            />
            <IconButton
              aria-label="Additional Items"
              variant={"none"}
              className="text-dark fs-5"
              icon={<GoChevronDown />}
            />
          </InputGroup> */}
          {/* <InputGroup maxW="xs">
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="muted" boxSize="5" />
          </InputLeftElement>
          <Input placeholder="Search" />
        </InputGroup> */}
          {/* <div className="dropdown"> */}
          {/* </div> */}
        </Stack>
      </Box>
      <hr />

      <Box px={{ base: "4", md: "6" }} pt="3" pb="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <AddItemForm />
            <PullOutItems />
            <ExportCSV
              fields={props.csvFields}
              data={props.csvData}
              filename="materials.csv"
            />
          </HStack>
          <div className="d-flex align-items-center">
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                search();
              }}
            >
              <InputGroup maxW="xs">
                <InputRightElement pointerEvents="none"></InputRightElement>
                <Input
                  value={text}
                  onChange={(e: any) => setText(e.target.value)}
                  placeholder="Search"
                />
                <button className="btn btn-primary text-light rounded py-0 px-2 me-2">
                  <Icon
                    as={FaSearch}
                    // color="teal"
                    textColor={"#fff"}
                    boxSize="3"
                  />
                </button>
              </InputGroup>
            </form>

            {/* <span className="ps-4 d-flex">
              <button className="btn text-dark p-1">
                <GoChevronLeft fontSize={18} />
              </button>
              <button className="btn text-dark p-1">
                <GoChevronRight fontSize={18} />
              </button>
            </span> */}
          </div>
        </Stack>
      </Box>
    </div>
  );
}

export default Header;
