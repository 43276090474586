import {
  Box,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { BsArrowRight } from "react-icons/bs";
import { IoArrowDown } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import * as yup from "yup";
import { labourPaymentData } from "../../../../Services/Atoms/MaterialAtoms";
import { BASE_URL } from "../../../../Services/Config";
import { LabourPaymentValues } from "../../../../Services/Interfaces/UserInterface";
import DeleteLabourPayment from "./../Forms/UserModal/DeleteLabourPayment";
import UpdatePayment from "./../Forms/UserModal/UpdatePayment";

// Define Yup schema for validation
const labourPaymentSchema = yup.object().shape({
  referenceId: yup.string().required(),
  name: yup.string().required(),
  amount: yup.number().required(),
  tds: yup.number().required(),
  paymentDate: yup.date().required(),
  utr: yup.string(),
  tcs: yup.number(),
  remark: yup.string(),
});

interface LabourPayment {
  _id?: string; // Assuming each payment has a unique ID for updating
  referenceId: string;
  name: string;
  amount: number;
  tds: number;
  paymentDate: string;
  utr?: string;
  tcs?: number;
  remark?: string;
  isApproved?: boolean;
  panNumber: string;
  accountHolderName: string;
  accountNumber: string;
  ifscCode: string;
  bankName: string;
  branch: string;
}

const LabourPaymentTable: React.FC = () => {
  const [members, setMembers] = useRecoilState(labourPaymentData);

  const navigate = useNavigate();

  useEffect(() => {
    fetchLabourPayments();
  }, []);

  const fetchLabourPayments = async () => {
    try {
      const response = await axios.get<LabourPayment[]>(
        BASE_URL + "api/labourPayments"
      );
      setMembers(response.data);
    } catch (error: any) {
      handleFetchError(error);
    }
  };

  const handleFetchError = (error: AxiosError<yup.ValidationError>) => {
    if (error.response?.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Failed to fetch labour payments.");
    }
  };

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">Reference ID</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">Name</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">Amount</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">TDS</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">Net Payment</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">Payment Date</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">UTR</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">TCS</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          {/* <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">PAN Number</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">Account Holder Name</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">Account Number</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">IFSC Code</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th>
          <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">Bank Name</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th> */}
          {/* <Th>
            <HStack spacing="3">
              <Checkbox />
              <HStack spacing="1">
                <Text className="mb-0">Branch</Text>
                <Icon as={IoArrowDown} color="muted" boxSize="4" />
              </HStack>
            </HStack>
          </Th> */}
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody fontSize="15px">
        {members &&
          members.map((labourPayment: LabourPaymentValues, index: number) => (
            <Tr key={index}>
              <Td>
                <HStack spacing="3">
                  <Checkbox />
                  <Box>
                    <Text className="mb-0" fontWeight="medium">
                      {labourPayment.referenceId}
                    </Text>
                  </Box>
                </HStack>
              </Td>
              <Td>
                <Text className="mb-0" color="muted">
                  {labourPayment.name}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="#777">
                  {labourPayment.amount}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="#777">
                  {labourPayment.tds}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="#777">
                  {labourPayment.amount - labourPayment.tds}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="muted">
                  {labourPayment.paymentDate}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="muted">
                  {labourPayment.utr}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="muted">
                  {labourPayment.tcs}
                </Text>
              </Td>
              {/* <Td>
                <Text className="mb-0" color="muted">
                  {labourPayment.remark}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="muted">
                  {labourPayment.panNumber}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="muted">
                  {labourPayment.accountHolderName}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="muted">
                  {labourPayment.accountNumber}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="muted">
                  {labourPayment.ifscCode}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="muted">
                  {labourPayment.bankName}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="muted">
                  {labourPayment.branch}
                </Text>
              </Td> */}
              <Td>
                {!labourPayment.isApproved ? (
                  <HStack spacing="1">
                    <UpdatePayment paymentData={labourPayment} />
                    <DeleteLabourPayment paymentData={labourPayment} />
                    {/* <IconButton
                      variant={"ghost"}
                      className="rounded-circle"
                      aria-label="View Details"
                      onClick={() =>
                        navigate(`/labour-payment/${labourPayment._id}`)
                      }
                      icon={<BsArrowRight fontSize="1rem" />}
                    /> */}
                  </HStack>
                ) : (
                  "APPROVED"
                )}
              </Td>
            </Tr>
          ))}
      </Tbody>
    </Table>
  );
};

export default LabourPaymentTable;
