import {
  Button,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { FiTrash2 } from "react-icons/fi";
import { RxCrossCircled } from "react-icons/rx";
import { useRecoilState } from "recoil";
import { useDeleteQuery } from "../../../../../Services/API/APIHandlers";
import { labourPaymentData } from "../../../../../Services/Atoms/MaterialAtoms";
import { LabourPaymentValues } from "./../../../../../Services/Interfaces/UserInterface";

interface DeleteLabourPaymentProps {
  paymentData: LabourPaymentValues;
}

const DeleteLabourPayment: React.FC<DeleteLabourPaymentProps> = ({
  paymentData,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [members, setMembers] = useRecoilState(labourPaymentData);

  const {
    mutate: deletePayment,
    isLoading,
    error: deleteError,
    data,
    isSuccess,
  } = useDeleteQuery("api/labourPayments/" + paymentData._id); // Adjust endpoint based on your API route

  useEffect(() => {
    if (deleteError instanceof AxiosError) {
      toast.error(deleteError?.response?.data.message);
    }
  }, [deleteError]);

  useEffect(() => {
    if (isSuccess) {
      if (members) {
        setMembers(data.data.data);
      }
      toast.success("Labour Payment deleted successfully");
      onClose();
    }
  }, [isSuccess, paymentData._id, members, setMembers, onClose]);

  return (
    <>
      <IconButton
        icon={<FiTrash2 fontSize="1rem" />}
        className="rounded-circle"
        variant="ghost"
        aria-label="Delete Labour Payment"
        onClick={onOpen}
      />
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size={"md"}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ModalHeader>
              <h4 className="text-center">Confirm Deletion</h4>
            </ModalHeader>

            <ModalBody
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              pb={7}
            >
              <Icon
                as={RxCrossCircled}
                color="muted"
                textColor={"red"}
                boxSize="100px"
              />
              <span>Are you sure you want to delete this Labour Payment?</span>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  deletePayment();
                }}
                marginRight={4}
                colorScheme={"red"}
                isLoading={isLoading}
              >
                Delete
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteLabourPayment;
