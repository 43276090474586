import { InventoryTable } from "./InventoryTable";

function HR() {
  return (
    <div>
      <InventoryTable />
    </div>
  );
}

export default HR;
