export const fetchDistanceString = (distance: number) => {
  let distanceValue: number = parseFloat(distance.toFixed(3));
  if (distanceValue > 1) return `${Math.round(distanceValue)} KMs`;
  return `${distanceValue * 1000} m`;
};

export const fetchTimeString = (time: number) => {
  let timeValue: number = time;
  if (timeValue > 60) return `${(timeValue / 60).toFixed(1)} hrs`;
  return `${timeValue} mins`;
};

// export const fetchTime = (timestamp: string) => {
//     const date = new Date(timestamp);
//     return date.toLocaleTimeString("en-US", { hour12: true });
// }

export const fetchTime = (timeString: string) => {
  const [hours, minutes] = timeString.split(":");
  const date = new Date();
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));
  const formattedTime = date.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
  });
  return formattedTime;
};

export const fetchDate = (timestamp: string | undefined) => {
  if (timestamp) {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }
};

export function formatDate(dateString: string): string {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const date = new Date(dateString);
  // const dayOfWeek = days[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const suffix = getOrdinalSuffix(dayOfMonth);

  // return `${dayOfWeek}, ${dayOfMonth}${suffix} ${month} ${year}`;
  return `${dayOfMonth}${suffix} ${month}, ${year}`;
}

function getOrdinalSuffix(dayOfMonth: number): string {
  const ones = dayOfMonth % 10;
  const tens = Math.floor(dayOfMonth / 10) % 10;

  if (tens === 1) {
    return "th";
  } else if (ones === 1) {
    return "st";
  } else if (ones === 2) {
    return "nd";
  } else if (ones === 3) {
    return "rd";
  } else {
    return "th";
  }
}

export function timeElapsed(timestamp: string): string {
  const ONE_MINUTE = 60 * 1000; // milliseconds
  const ONE_HOUR = 60 * ONE_MINUTE;
  const ONE_DAY = 24 * ONE_HOUR;
  const ONE_MONTH = 30 * ONE_DAY;
  const ONE_YEAR = 365 * ONE_DAY;

  const date = new Date(timestamp);
  const now = new Date();
  const elapsed = now.getTime() - date.getTime();

  if (elapsed < ONE_HOUR) {
    const minutes = Math.floor(elapsed / ONE_MINUTE);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (elapsed < ONE_DAY) {
    const hours = Math.floor(elapsed / ONE_HOUR);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (elapsed < ONE_MONTH) {
    const days = Math.floor(elapsed / ONE_DAY);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (elapsed < ONE_YEAR) {
    const months = Math.floor(elapsed / ONE_MONTH);
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else {
    const years = Math.floor(elapsed / ONE_YEAR);
    return `${years} year${years > 1 ? "s" : ""} ago`;
  }
}

export function getTime(dateString: string | null) {
  if (dateString) {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }
}

export function getDayYear(dateString: string | null) {
  if (dateString) {
    const date = new Date(dateString);
    const day = date.toLocaleString("default", { weekday: "short" });
    const year = date.getFullYear();
    return `${day} ${year}`;
  }
}

export function getMonthDate(dateString: string | null) {
  if (dateString) {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    return `${day} ${month}`;
  }
}
