import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { MemberTable } from "./MemberTable";
// import { MemberTable } from "./MemberTable";
import axios from "axios";
import { useEffect } from "react";
import Header from "./Header";
import { BASE_URL } from "../../../../Services/Config";

function extractAccessToken(html: any) {
  // Regular expression to match the access token in the HTML text
  const tokenRegex = /Access Token: ([\w\-.]+)/;
  const match = html.match(tokenRegex);

  // Check if a match was found
  if (match && match[1]) {
    return match[1]; // Return the access token
  } else {
    throw new Error("Access Token not found");
  }
}

export const Table = () => {
  useEffect(() => {
    // Function to get URL parameters
    const getQueryParams = () => {
      const params = new URLSearchParams(window.location.search);
      return {
        code: params.get("code"),
        sessionState: params.get("session_state"),
      };
    };

    const { code, sessionState } = getQueryParams();

    if (code) {
      // Call your Express server with the authorization code
      axios
        .get(BASE_URL + `callback?code=${code}`)
        .then((response) => {
          // Save the access token in session storage
          console.log(response.data);
          sessionStorage.setItem(
            "accessToken",
            extractAccessToken(response.data)
          );
        })
        .catch((error) => {
          console.error("Error fetching access token:", error);
        });
    } else {
      console.error("No authorization code found in the URL");
    }
  }, []);

  const isMobile = useBreakpointValue({ base: false, md: false });
  return (
    <>
      <Box
        bg="bg-surface"
        boxShadow={{ base: "none", md: useColorModeValue("sm", "sm-dark") }}
        borderRadius={useBreakpointValue({ base: "none", md: "lg" })}
      >
        <Header />
        <Stack spacing="5">
          <Box overflowX="auto">
            <MemberTable />
          </Box>
          <Box px={{ base: "4", md: "6" }} pb="5">
            <HStack spacing="3" justify="space-between">
              {!isMobile && (
                <Text color="muted" fontSize="sm">
                  Showing 1 to 5 of 42 results
                </Text>
              )}
              <ButtonGroup
                spacing="3"
                justifyContent="space-between"
                width={{ base: "full", md: "auto" }}
                variant="secondary"
              >
                <Button>Previous</Button>
                <Button>Next</Button>
              </ButtonGroup>
            </HStack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
