import PartyPaymentTable from "./PartyPaymentTable/PartyPtTable";

function PartyPayment() {
  return (
    <div>
      <PartyPaymentTable />
    </div>
  );
}

export default PartyPayment;
