import {
  Box,
  Button,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";

interface SignatureProps {
  isOpen: boolean;
  onClose: () => void;
  onDropSignature: (acceptedFiles: File[]) => void;
  signature: File | null;
}

const Signature: React.FC<SignatureProps> = ({
  isOpen,
  onClose,
  onDropSignature,
  signature,
}) => {
  const signatureDropzone = useDropzone({
    onDrop: onDropSignature,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    multiple: false,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Signature</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            {...signatureDropzone.getRootProps()}
            p={4}
            borderWidth={2}
            borderRadius="md"
            borderColor="gray.300"
            borderStyle="dashed"
            textAlign="center"
            cursor="pointer"
          >
            <input {...signatureDropzone.getInputProps()} />
            {signature ? (
              <Box>
                <p>Uploaded Signature:</p>
                <Image src={URL.createObjectURL(signature)} alt="Signature" />
              </Box>
            ) : (
              <Box>
                <Icon as={FiUpload} boxSize={6} color="gray.400" />
                <p>Drag 'n' drop signature here, or click to select</p>
              </Box>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Signature;
