// src/components/ImagePreviewModal.tsx
import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import ReactHover, { Hover, Trigger } from "react-hover";
import { Link } from "react-router-dom";

type ImagePreviewModalProps = {
  imageUrl: string;
};

const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({ imageUrl }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const options = {
    followCursor: false,
    shiftX: 20,
    shiftY: 0,
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "challan_image.jpg"; // Specify a filename here
    link.target = "_blank"; // Open in new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <ReactHover options={options}>
        <Trigger type="trigger">
          <Text as={Link} style={{ cursor: "pointer" }} onClick={onOpen}>
            Show Challan
          </Text>
        </Trigger>
        <Hover type="hover">
          <Box
            p={2}
            bg="white"
            border="1px"
            borderColor="gray.200"
            borderRadius="md"
            boxShadow="md"
          >
            <Image src={imageUrl} alt="Preview" boxSize="100px" />
          </Box>
        </Hover>
      </ReactHover>

      <Modal isOpen={isOpen} size={"xl"} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Challan Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={imageUrl} alt="Preview" />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="green" onClick={handleDownload}>
              Download
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImagePreviewModal;
