import axios from "axios";
// import { Commission } from "../types/CommissionType";

import { jwtDecode } from "jwt-decode";
import { useMutation } from "react-query";
import { setRecoil } from "recoil-nexus";
import {
  accessToken,
  cUserId,
  isAuthenticated,
  localTokenSet,
  refreshToken,
} from "./../Atoms/AuthAtoms";
import { BASE_URL } from "./../Config";

const token = () => {
  return isTokenValid();
};
// const headers = () => { return { "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjgzNTM2NzkyLCJpYXQiOjE2ODM0NzAxMzIsImp0aSI6IjBhYTVhNjJiZDRiOTRkMmM4MDI1NDk2OWE0ZGE1NjU2IiwidXNlcl9pZCI6Mn0.b5p2GEdZeTY6HBypw6qoa4h8tcCkig-OlxaCyOn_-uY` } };
const headers = () => {
  return { Authorization: `Bearer ${token()}` };
};
// const headers = () => { return { "Authorization": `Bearer ${token()}`, "X-Auth-Token": X_ORG_TOKEN } };
// const noAuthHeaders = () => { return { "X-Auth-Token": X_ORG_TOKEN } };
// const noAuthHeaders = () => { return { "X-Auth-Token": X_ORG_TOKEN, "Content-Type": "application/json" } };
const noAuthHeaders = () => {
  return { "Content-Type": "application/json" };
};

// Non Authorized
// GET
const getQuery = (route: string) =>
  axios.get(BASE_URL + route, { headers: noAuthHeaders() });
export const useGet = (route: string) => {
  return useMutation("GET_CALL", () => getQuery(route));
};

// POST
const postQuery = (route: string, data: any) =>
  axios.post(BASE_URL + route, data, { headers: noAuthHeaders() });
export const usePost = (route: string) => {
  return useMutation("POST_CALL", (data: any) => postQuery(route, data));
};

// PUT
const putQuery = (route: string, data: any) =>
  axios.put(BASE_URL + route, data, { headers: noAuthHeaders() });
export const usePut = (route: string) => {
  return useMutation("PUT_CALL", (data: any) => putQuery(route, data));
};

// DELETE
const deleteQuery = (route: string) =>
  axios.delete(BASE_URL + route, { headers: noAuthHeaders() });
export const useDeleteQuery = (route: string) => {
  return useMutation("DELETE_CALL", () => deleteQuery(route));
};

// LATE GET
export const useLateGet = () => {
  return useMutation("LATE_GET_CALL", (route: string) => getQuery(route));
};

// LATE POST
// export const useLatePost = () => {T_CALL
//     return useMutation('POS', (route: string, data: any) => postQuery(route, data));
// }

// // LATE PATCH
// export const useLatePatch = () => {
//     return useMutation('PATCH_CALL', (route: string, data: any) => patchQuery(route, data));
// }

// // LATE DELETE
export const useLateDeleteQuery = () => {
  return useMutation("LATE_ DELETE_CALL", (route: string) =>
    deleteQuery(route)
  );
};

// Authorized
// GET
const getQueryValidate = (route: string) =>
  axios.get(BASE_URL + route, { headers: headers() });
export const useGetValidate = (route: string) => {
  return useMutation("AUTHORIZED_GET_CALL", () => getQueryValidate(route));
};

// POST
const postQueryValidate = (route: string, data: any) =>
  axios.post(BASE_URL + route, data, { headers: headers() });
export const usePostValidate = (route: string) => {
  return useMutation("AUTHORIZED_POST_CALL", (data: any) =>
    postQueryValidate(route, data)
  );
};

// PUT
const putQueryValidate = (route: string, data: any) =>
  axios.put(BASE_URL + route, data, { headers: headers() });
export const usePutValidate = (route: string) => {
  return useMutation("AUTHORIZED_PUT_CALL", (data: any) =>
    putQueryValidate(route, data)
  );
};

// DELETE
const deleteQueryValidate = (route: string) =>
  axios.delete(BASE_URL + route, { headers: headers() });
export const useDeleteQueryValidate = (route: string) => {
  return useMutation("AUTHORIZED_DELETE_CALL", () =>
    deleteQueryValidate(route)
  );
};

// Validate Token
export function isTokenValid() {
  const token = JSON.parse(localStorage.getItem("token") || "{}");
  const accessTokenValue = token.accessToken;
  try {
    let tokenData: any = jwtDecode(accessTokenValue);
    if (Date.now() >= tokenData?.exp * 1000) {
      return false;
    }
    setRecoil(localTokenSet, {
      refreshToken: token.refreshToken,
      accessToken: token.accessToken,
    });
    setRecoil(cUserId, tokenData._id);
    console.log(tokenData);
    setRecoil(accessToken, accessTokenValue);
    setRecoil(refreshToken, token.refreshToken);
    setRecoil(isAuthenticated, true);
    return { accessTokenValue, tokenData };
  } catch (err: any) {
    return false;
  }
}

export const PAYMENT_API = BASE_URL + "/create-order";
export const CALLBACK_API = BASE_URL + "/redirect-payment";
export const STUDENT_PAYMENT_SUCCESS_API =
  BASE_URL + "/student-payment-success";
export const SUCCESS_API = BASE_URL + "/confirm-payment";

export function getPricing(trainingCode: String) {
  if (trainingCode === "FWD") {
    return 5499;
  }
  if (trainingCode === "LC") {
    return 2499;
  }
  if (trainingCode === "DS") {
    return 11999;
  }
}

export { token };
