import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import * as Yup from "yup";
import { usePost } from "../../../../../Services/API/APIHandlers";
import { accessType } from "../../../../../Services/Atoms/AuthAtoms";
import { labourPaymentData } from "../../../../../Services/Atoms/MaterialAtoms";
import { LabourPaymentValues } from "../../../../../Services/Interfaces/UserInterface";

const CreatePayment: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [members, setMembers] = useRecoilState(labourPaymentData);
  const [accessTypeData, setAccessTypeData] = useRecoilState(accessType);

  const {
    mutate: createNewPayment,
    isLoading,
    error: createNewPaymentError,
    data: createNewPaymentData,
    isSuccess: createNewPaymentSuccess,
  } = usePost("api/labourPayments");

  useEffect(() => {
    if (createNewPaymentError instanceof AxiosError) {
      toast.error(createNewPaymentError?.response?.data.message);
    }
  }, [createNewPaymentError]);

  useEffect(() => {
    if (createNewPaymentSuccess) {
      setMembers(createNewPaymentData.data.data);
      onClose();
    }
  }, [createNewPaymentSuccess, createNewPaymentData, setMembers, onClose]);

  const validationSchema = Yup.object().shape({
    referenceId: Yup.string().required("Reference ID is required"),
    name: Yup.string().required("Name is required"),
    amount: Yup.number().required("Amount is required"),
    tds: Yup.number(),
    paymentDate: Yup.date().required("Payment Date is required"),
    utr: Yup.string(),
    tcs: Yup.number(),
    remark: Yup.string(),
    panNumber: Yup.string(),
    accountHolderName: Yup.string(),
    accountNumber: Yup.string(),
    ifscCode: Yup.string(),
    bankName: Yup.string(),
    branch: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      referenceId: "",
      name: "",
      amount: 0,
      tds: 0,
      paymentDate: (new Date()).toISOString(),
      utr: "",
      tcs: 0,
      remark: "",
      panNumber: "",
      accountHolderName: "",
      accountNumber: "",
      ifscCode: "",
      bankName: "",
      branch: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: LabourPaymentValues) => {
      createNewPayment(values);
    },
  });

  console.log(accessTypeData);

  return (
    <>
      <Link onClick={onOpen} className="dropdown-item" to="#">
        Create Payment
      </Link>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size={"xl"}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody padding={"20px"}>
            <ModalCloseButton />
            <h4 className="text-center mb-3">Create a Payment</h4>
            <Form onSubmit={formik.handleSubmit}>
              <Card className="rounded-5 border-muted">
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="referenceId">
                        <Form.Label>Reference ID</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Reference ID"
                          {...formik.getFieldProps("referenceId")}
                          isInvalid={
                            formik.touched.referenceId &&
                            !!formik.errors.referenceId
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.referenceId}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          {...formik.getFieldProps("name")}
                          isInvalid={
                            formik.touched.name && !!formik.errors.name
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="amount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Amount"
                          {...formik.getFieldProps("amount")}
                          isInvalid={
                            formik.touched.amount && !!formik.errors.amount
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.amount}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="paymentDate">
                        <Form.Label>Payment Date</Form.Label>
                        <Form.Control
                          type="date"
                          {...formik.getFieldProps("paymentDate")}
                          isInvalid={
                            formik.touched.paymentDate &&
                            !!formik.errors.paymentDate
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {/* {formik.errors.paymentDate} */}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="panNumber">
                        <Form.Label>PAN Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="PAN Number"
                          {...formik.getFieldProps("panNumber")}
                          isInvalid={
                            formik.touched.panNumber &&
                            !!formik.errors.panNumber
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.panNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="pt-3"
                        controlId="accountHolderName"
                      >
                        <Form.Label>Account Holder Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Account Holder Name"
                          {...formik.getFieldProps("accountHolderName")}
                          isInvalid={
                            formik.touched.accountHolderName &&
                            !!formik.errors.accountHolderName
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.accountHolderName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="accountNumber">
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Account Number"
                          {...formik.getFieldProps("accountNumber")}
                          isInvalid={
                            formik.touched.accountNumber &&
                            !!formik.errors.accountNumber
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.accountNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="ifscCode">
                        <Form.Label>IFSC Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="IFSC Code"
                          {...formik.getFieldProps("ifscCode")}
                          isInvalid={
                            formik.touched.ifscCode && !!formik.errors.ifscCode
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.ifscCode}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="bankName">
                        <Form.Label>Bank Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Bank Name"
                          {...formik.getFieldProps("bankName")}
                          isInvalid={
                            formik.touched.bankName && !!formik.errors.bankName
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.bankName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="branch">
                        <Form.Label>Branch</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Branch"
                          {...formik.getFieldProps("branch")}
                          isInvalid={
                            formik.touched.branch && !!formik.errors.branch
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.branch}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="remark">
                        <Form.Label>Remark</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Remark"
                          {...formik.getFieldProps("remark")}
                          isInvalid={
                            formik.touched.remark && !!formik.errors.remark
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.remark}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    {accessTypeData === "ADMIN" ||
                    accessTypeData === "SUPER ADMIN" ||
                    accessTypeData === "SUPER_ADMIN" ? (
                      <>
                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="tds">
                            <Form.Label>TDS</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="TDS"
                              {...formik.getFieldProps("tds")}
                              isInvalid={
                                formik.touched.tds && !!formik.errors.tds
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.tds}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="utr">
                            <Form.Label>UTR</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="UTR"
                              {...formik.getFieldProps("utr")}
                              isInvalid={
                                formik.touched.utr && !!formik.errors.utr
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.utr}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="tcs">
                            <Form.Label>TCS</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="TCS"
                              {...formik.getFieldProps("tcs")}
                              isInvalid={
                                formik.touched.tcs && !!formik.errors.tcs
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.tcs}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Card.Body>
              </Card>
              <Button
                className="btn btn-primary mt-4 d-block text-uppercase w-100 fw-bold"
                type="submit"
              >
                Create Payment
              </Button>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreatePayment;
