import {
  HStack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { useGet } from "../../../../Services/API/APIHandlers";
import { materialData } from "../../../../Services/Atoms/MaterialAtoms";
import { formValuesTable } from "../../../../Services/Types/MaterialType";
import EditItem from "../Forms/UserModal/UpdateItem";
// import { items } from "./data";
import DeleteMaterial from "./../Forms/UserModal/DeleteMaterial";

export const ItemsTable = (props: TableProps) => {
  const [items, setItems] = useRecoilState(materialData);

  const {
    mutate: getMaterialData,
    isLoading,
    error: materialDataError,
    data: materialsData,
    isSuccess,
  } = useGet("api/materials/all");

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setItems(materialsData.data);
    }
  }, [isSuccess, materialsData]);

  useEffect(() => {
    getMaterialData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Table overflowX={"scroll"} {...props}>
      <Thead>
        <Tr>
          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text className="mb-0">Item Name</Text>
                {/* <Icon as={IoArrowDown} color="muted" boxSize="4" /> */}
              </HStack>
            </HStack>
          </Th>

          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text className="mb-0">Brand Name</Text>
                {/* <Icon as={IoArrowDown} color="muted" boxSize="4" /> */}
              </HStack>
            </HStack>
          </Th>

          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text className="mb-0">Length/Volume</Text>
                {/* <Icon as={IoArrowDown} color="muted" boxSize="4" /> */}
              </HStack>
            </HStack>
          </Th>

          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text className="mb-0">L/V Unit</Text>
                {/* <Icon as={IoArrowDown} color="muted" boxSize="4" /> */}
              </HStack>
            </HStack>
          </Th>

          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text className="mb-0">Quantity</Text>
                {/* <Icon as={IoArrowDown} color="muted" boxSize="4" /> */}
              </HStack>
            </HStack>
          </Th>

          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text className="mb-0">Qty Unit</Text>
                {/* <Icon as={IoArrowDown} color="muted" boxSize="4" /> */}
              </HStack>
            </HStack>
          </Th>

          <Th>
            <HStack spacing="3">
              <HStack spacing="1">
                <Text className="mb-0">Position</Text>
                {/* <Icon as={IoArrowDown} color="muted" boxSize="4" /> */}
              </HStack>
            </HStack>
          </Th>
          {/* <Th>
          <HStack spacing="3">
            
            <HStack spacing="1">
              <Text className="mb-0">Name</Text>
              // <Icon as={IoArrowDown} color="muted" boxSize="4" />
            </HStack>
          </HStack>
        </Th> */}

          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody fontSize={"15px"}>
        {items &&
          items.map((item: formValuesTable, index: number) => {
            return (
              <>
                <Tr key={index}>
                  <Td>
                    <Text className="mb-0" color="muted">
                      {item.item_name?.value}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="muted">
                      {item.brand_name}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="muted">
                      {item.lv}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="#777">
                      {item.lv_unit?.label}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="#777">
                      {item.quantity ? item?.quantity : ""}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="#777">
                      {item.qt_unit?.label}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="#777">
                      {(item.block?.label ? item.block?.label : "") +
                        item.column?.label +
                        item.cell?.label}
                    </Text>
                  </Td>

                  <Td>
                    <HStack spacing="1">
                      <EditItem data={item} />
                      <DeleteMaterial data={item} />
                      {/* <DeleteUser data={item} />
                    <IconButton
                      variant={"ghost"}
                      className="rounded-circle"
                      aria-label="View Details"
                      icon={<HiOutlineDotsVertical fontSize="1rem" />}
                    /> */}
                    </HStack>
                  </Td>
                </Tr>
              </>
            );
          })}
      </Tbody>
    </Table>
  );
};
