import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import { BsArrowRepeat } from "react-icons/bs";
import { GoChevronDown, GoChevronLeft, GoChevronRight } from "react-icons/go";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoIosList } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import ExportCSV from "../../../components/CSVExport/ExportToCSV";
import { FaSearch } from "react-icons/fa";
import { text } from "stream/consumers";
import { useRecoilState } from "recoil";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useGet } from "../../../../Services/API/APIHandlers";
import { transactionData } from "../../../../Services/Atoms/MaterialAtoms";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];


type propType = {
  csvData: Record<string, any>[] | undefined;
  csvFields: string[];
};

function Header(props: propType) {

   const [items, setItems] = useRecoilState(transactionData);

   const [text, setText] = useState<string>("");

   const {
     mutate: getMaterialData,
     isLoading: isMaterialLoading,
     error: materialDataAllError,
     data: allMaterialsData,
     isSuccess: isAllMaterialSuccess,
   } = useGet("api/materials/transactions");

   const {
     mutate: search,
     isLoading,
     error: materialDataError,
     data: materialsData,
     isSuccess,
   } = useGet("api/materials/list?q=" + text);

   useEffect(() => {
     if (materialDataError instanceof AxiosError) {
       // Call a toast
       toast.error(materialDataError?.response?.data.message);
     }
   }, [materialDataError]);

   useEffect(() => {
     if (isSuccess) {
       setItems(materialsData.data);
     }
   }, [isSuccess, materialsData]);

   useEffect(() => {
     if (isAllMaterialSuccess) {
       setItems(allMaterialsData.data);
     }
   }, [isAllMaterialSuccess, allMaterialsData]);

   useEffect(() => {
     if (text.length === 0) {
       getMaterialData();
     }
   }, [text]);

  return (
    <>
      <Box px={{ base: "4", md: "6" }} pt="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="dropdown show">
              <Link
                className="fs-7 py-1 d-flex flex-row align-items-center me-1 dropdown-toggle"
                to="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <h4 className="my-0">Inventory Transactions</h4>
              </Link>

              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a className="dropdown-item" href="#">
                  Action
                </a>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
            </div>
            <div className="dropdown">
              <Link
                className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Create User
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>{/* <CreateUser /> */}</li>
                <li>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>
          </HStack>
          <InputGroup maxW="xs" className="justify-content-end">
            <IconButton
              icon={<IoIosList />}
              variant={"none"}
              className="text-dark fs-5"
              aria-label={"More"}
            />
            <IconButton
              icon={<IoSettingsOutline />}
              variant={"none"}
              className="text-dark fs-5"
              aria-label="Settings"
            />
            <div className="mx-3 border-end"></div>
            <IconButton
              icon={<BsArrowRepeat />}
              variant={"none"}
              aria-label="Refresh"
              className="text-dark fs-5"
            />
            <IconButton
              variant={"none"}
              className="text-dark fs-5"
              aria-label={"More Button"}
              icon={<HiOutlineDotsVertical />}
            />
            <IconButton
              aria-label="Additional Items"
              variant={"none"}
              className="text-dark fs-5"
              icon={<GoChevronDown />}
            />
          </InputGroup>
          {/* <InputGroup maxW="xs">
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="muted" boxSize="5" />
          </InputLeftElement>
          <Input placeholder="Search" />
        </InputGroup> */}
        </Stack>
      </Box>
      <hr />

      <Box px={{ base: "4", md: "6" }} pt="3" pb="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="dropdown">
              <Link
                className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Bulk Actions
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>{/* <CreateUser /> */}</li>
                <li>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div>

            <Button colorScheme="gray" size={"sm"} fontWeight={"normal"}>
              Apply
            </Button>
            <div className="d-flex flex-row align-items-center">
              <span className="px-2 pe-3">Sort By: </span>
              <div className="dropdown show">
                <a
                  className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Bulk actions
                </a>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </div>
            </div>
            <ExportCSV
              fields={props.csvFields}
              data={props.csvData}
              filename="material-transactions.csv"
            />
          </HStack>
          <div className="d-flex align-items-center">
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                search();
              }}
            >
              <InputGroup maxW="xs">
                <InputRightElement pointerEvents="none"></InputRightElement>
                <Input
                  value={text}
                  onChange={(e: any) => setText(e.target.value)}
                  placeholder="Search"
                />
                <button className="btn btn-primary text-light rounded py-0 px-2 me-2">
                  <Icon
                    as={FaSearch}
                    // color="teal"
                    textColor={"#fff"}
                    boxSize="3"
                  />
                </button>
              </InputGroup>
            </form>
            {/* <span className="ps-4 d-flex">
              <button className="btn text-dark p-1">
                <GoChevronLeft fontSize={18} />
              </button>
              <button className="btn text-dark p-1">
                <GoChevronRight fontSize={18} />
              </button>
            </span> */}
          </div>
        </Stack>
      </Box>
    </>
  );
}

export default Header;
