// src/components/NotificationSystem.tsx
import {
  Box,
  Button,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoIosNotificationsOutline } from "react-icons/io";
import { io, Socket } from "socket.io-client";

const socket: Socket = io("http://localhost:5000"); // Ensure this matches your server's URL

const NotificationSystem: React.FC = () => {
  const [notifications, setNotifications] = useState<
    { message: string; url: string }[]
  >([]);

  // useEffect(() => {
  //   // Request permission for browser notifications
  //   if (Notification.permission !== "granted") {
  //     Notification.requestPermission();
  //   }

  //   // Listen for notification events from the server
  //   socket.on("notification", (data: { message: string; url: string }) => {
  //     setNotifications((prevNotifications) => [
  //       { message: data.message, url: data.url },
  //       ...prevNotifications,
  //     ]);

  //     // Show browser notification
  //     if (Notification.permission === "granted") {
  //       const notification = new Notification("New Notification", {
  //         body: data.message,
  //       });
  //       notification.onclick = () => {
  //         window.open(data.url, "_blank");
  //       };
  //     }
  //   });

  //   return () => {
  //     socket.off("notification");
  //   };
  // }, []);

  return (
    <Flex justifyContent="center" alignItems="center">
      <Box>
        <Menu>
          <MenuButton
            paddingLeft={1}
            paddingRight={3}
            rounded={20}
            as={Button}
            rightIcon={<IoIosNotificationsOutline />}
          ></MenuButton>
          <MenuList>
            {notifications.length === 0 ? (
              <MenuItem>No notifications</MenuItem>
            ) : (
              notifications.map((notification, index) => (
                <MenuItem key={index}>
                  <Box>
                    <Text fontWeight="bold">{notification.message}</Text>
                    <Link href={notification.url} isExternal>
                      Visit
                    </Link>
                  </Box>
                </MenuItem>
              ))
            )}
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};

export default NotificationSystem;
