import { LabourPaymentModule } from "./LabourPaymentTable";

function LabourPayment() {
  return (
    <div>
      <LabourPaymentModule />
    </div>
  );
}

export default LabourPayment;
